<template>
    <div class="business-detail content">
        <div class="bread">
            <bread :data="bread"></bread>
        </div>

        <el-row :gutter="12">
            <el-col :span="18">
                <card class="paper" v-loading="loadingController">
                    <div 
                        class="context rich-text" 
                        v-html="detail.ggcontent"
                    ></div>
                </card>
            </el-col>

            <el-col :span="6">
                <card class="time-card right-card" v-loading="loadingController">
                    <div slot="title" class="title">
                        时间汇总
                    </div>

                    <el-timeline class="time-line">
                        <el-timeline-item>
                            <div class="time-line-item">
                                <div class="title">公告时间</div>
                                <p>开始：{{detail.ggfbtime}}</p>
                                <p>截止：{{detail.ggendtime}}</p>
                            </div>
                        </el-timeline-item>
                    </el-timeline>
                </card>

                <card class="guide-card right-card">
                    <div slot="title" class="title">
                        投标指南
                    </div>
                    
                    <div class="guide-list">
                        <div class="item">
                            <img src="./assets/icon/file1.png" alt="">
                            <div class="text">获取招标文件</div>
                        </div>

                        <div class="item">
                            <img src="./assets/icon/file2.png" alt="">
                            <div class="text">递交/上传投标文件</div>
                        </div>
                    </div>
                </card>

                <!--<card class="enter-card right-card">
                    <div slot="title" class="title">
                        快捷入口
                    </div>
                    
                    <div class="enter-list">
                        <el-row :gutter="10">
                            <el-col :span="8">
                                <a class="item">
                                    CA办理
                                </a>
                            </el-col>
                            <el-col :span="8">
                                <a class="item">
                                    CA驱动
                                </a>
                            </el-col>
                            <el-col :span="8">
                                <a class="item">
                                    电子保函
                                </a>
                            </el-col>
                        </el-row>
                    </div>
                </card>-->
            </el-col>
        </el-row>
    </div>
</template>

<script>
import Card from '@components/sys/card';
import Bread from '@components/sys/bread';

import FileDownload from './components/file-download';
import LinkPaper from './components/link-paper';
import TableHxrgs from './components/table-hxrgs';

export default {
    components: {
        Card,
        Bread,
        FileDownload,
        LinkPaper,
        TableHxrgs,
    },
    data: () => ({
        bread: [{path: '/business', title: '交易信息'}],

        detail: {
            Data_model: {},
        },

        loadingController: false,
    }),
    methods: {
        queryDetail() {
            this.loadingController = true;

            var infoid = this.getQuery('ggcode'),
                categoryid = this.getQuery('categoryid'),
                url = `${this.$store.state.api.webUrl}/index/expand/ggdetail.json`,
                search = {
                    infoid,
                    categoryid,
                };

            this.$get(url, search, data => {
                this.detail = data;

                this.bread.push({
                    path: this.$route.fullPath,
                    title: '公告详情',
                });

                this.loadingController = false;
            });
        },
    },
    mounted() {
        this.queryDetail();
    },
};
</script>

<style lang="scss" scoped>
@import '@css/var.scss';

$tableBorder: 1px #dfdfdf solid;

.business-detail{
    margin-bottom: 26px;

    .paper{
        min-height: 700px;

        .title{
            font-size: 18px;
            color: #262626;
            text-align: center;
            padding-bottom: 24px;
            border-bottom: solid 1px #dfdfdf;
            margin-bottom: 1em;
        }

        .info-table {
            width: 100%;
            border-top: $tableBorder;
            border-left: $tableBorder;
            margin-bottom: 1em;

            td{
                border-right: $tableBorder;
                border-bottom: $tableBorder;
                padding: 1em;
            }
        }
    }

    .context{
        line-height: 2em;
        color: #262626;
    }

    .right-card{
        .title {
            color: #262626;
            font-size: 18px;
            font-weight: bold;
        }

        & + .right-card{
            margin-top: 12px;
        }
    }

    .time-card {

        .time-line {
            padding-left: 2px;
            padding-top: 8px;

            ::v-deep {
                .el-timeline-item__wrapper{
                    padding-left: 24px;
                    top: -6px;
                }

                .el-timeline-item__node{
                    background: $primaryColor;
                }
            }

            .time-line-item{
                background: $primaryColor1;
                padding: 12px;
                color: #262626;
                position: relative;

                &:before{
                    content: '';
                    display: block;
                    width: .8em;
                    height: .8em;
                    position: absolute;
                    left: 0;
                    top: 1em;
                    transform: translate(-50%, -50%) rotate(45deg);
                    background: $primaryColor1;
                }

                .title{
                    color: $primaryColor;
                    font-weight: bold;
                    margin-bottom: 10px;
                }

                p{
                    & + p{
                        margin-top: 10px;
                    }
                }
            }
        }
    }

    .guide-card{
        .guide-list{
            overflow: hidden;
            background-image: url(~./assets/icon/arrow.png);
            background-position: center top;
            background-repeat: no-repeat;

            .item{
                text-align: center;

                .text{
                    line-height: 1em;
                    margin-top: 12px;
                }

                &:first-child{
                    float: left;
                }

                &:last-child{
                    float: right;
                }
            }
        }
    }

    .enter-card{
        .enter-list{
            .item{
                display: block;
                box-sizing: border-box;
                text-align: center;
                color: $primaryColor;
                border: 1px solid $primaryColor;
                line-height: 1em;
                padding: 10px 0;
                transition: all .3s;

                &:hover{
                    background: $primaryColor;
                    color: white;
                }
            }
        }
    }
}
</style>