<template>
    <table class="link-paper" cellpadding="0" cellspacing="0">
        <tr v-if="data.htmla">
            <td>{{htmlaRebuild.title}}</td>
            <td>
                <!--
                <div v-html="htmlaRebuild.text"></div>-->
                <a 
                :href="'/business/detail?ggcode='+v.ggcode+'&categoryid=bggg'" 
                v-for="(v,i) in data.xggglist" 
                :key="i"
                v-if="v.ggxzcode==2"
                >
                    {{v.ggname}} （{{v.ggfbtime}}）
                </a>
                <a 
                :href="'/business/detail?ggcode='+v.ggcode+'&categoryid=zbgg'" 
                v-for="(v,i) in data.xggglist" 
                :key="i"
                v-if="v.ggxzcode==1"
                >
                    {{v.ggname}} （{{v.ggfbtime}}）
                </a>

                <div v-if="!htmlaRebuild.text">
                    本公告没有{{htmlaRebuild.title}}！
                </div>
            </td>
        </tr>

        <tr v-if="data.liDyCq">
            <td>补遗答遗：</td>
            <td>
                <a 
                :href="'/business/detail?ggcode='+v.GGCODE+'&categoryid=bydy'+'&active=0'" 
                v-for="(v,i) in data.liDyCq" 
                :key="i"
                >
                    {{v.GGNAME}} （{{v.GGFBTIME}}）
                </a>

                <div v-if="!data.liDyCq.length">
                    本公告没有补遗答遗！
                </div>
            </td>
        </tr>

        <tr v-if="data.liYcgg">
            <td>异常公告：</td>
            <td>
                <a 
                :href="'/business/detail?ggcode='+v.GGCODE+'&categoryid=ycgg'+'&active=0'"  
                v-for="(v,i) in data.liYcgg" 
                :key="i"
                >
                    {{v.GGNAME}} （{{v.GGFBTIME}}）
                </a>

                <div v-if="!data.liYcgg.length">
                    本公告没有异常公告！
                </div>
            </td>
        </tr>
    </table>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        htmlaRebuild() {
            return this.htmlaHandler(this.data.htmla);
        }
    },
    methods: {
        htmlaHandler(html) {
            if (!html) {
                return {
                    title: '',
                    text: '',
                };
            }

            // var matchFont = html.match(/<\/?font.*?>.*<\/font>/),
            //     matchText = html.match(/<\/?a.*?>.*<\/a>/),
            //     title = '',
            //     text = '';

            // if (matchFont) {
            //     title = matchFont[0],
            //     title = title.replace(/<\/?font.*?>/g, '');
            // }

            // if (matchText) {
            //     text = matchText[0];
            // }

            var title = html.split('<br/>')[0].replace(/<\/?font.*?>/g, '')
            var text = html.split('<br/>')[1]
            return {
                title,
                text,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
    $tableBorder: 1px #dfdfdf solid;

    .link-paper {
        width: 100%;
        border-top: $tableBorder;
        border-left: $tableBorder;
        margin-bottom: 1em;
        margin: 1em 0;

        td{
            border-right: $tableBorder;
            border-bottom: $tableBorder;
            padding: 1em;
        }
    }
</style>