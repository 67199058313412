<template>
  <div class="index">
    <div class="totop">
      <banner></banner>

      <!-- <div class="content sec2">
            <el-row :gutter="12">
                <el-col :span="18">
                    <news></news>
                </el-col>
                <el-col :span="6">
                    <enters></enters>
                </el-col>
            </el-row>
        </div> -->
      <div class="section-business">
        <business></business>
      </div>
      <div class="content">
        <news></news>

      </div>
      <footlink></footlink>
    </div>

    <!-- ca弹窗 -->
    <!-- <float-layer v-if="showView" :json="json" @closeView="closeView"></float-layer> -->

    <hotDialog></hotDialog>

  </div>
</template>

<script>
import Logins from './index/sections/logins'
import Banner from './index/sections/banner';
import News from './index/sections/news';
import Enters from './index/sections/enters';
import Business from './index/sections/business';
import Footlink from './index/sections/footlink'

import floatLayer from '@components/sys/float-layer';
import hotDialog from '@/components/sys/question/hot-dialog.vue';

import appNode from '@js/app-node';

export default {
  components: {
    Logins,
    Banner,
    News,
    Enters,
    Business,
    floatLayer,
    Footlink,
    hotDialog,
  },

  data: () => ({
    showView: true,
    json: {
      left: 100,
      top: 400,
      directionX: 1,
      directionY: 1,
      distance: null,
      interval: null,
      target: null,
      zIndex: null,
      width: null,
      height: null,
      noHoverPause: null, //鼠标经过时不停止，为true时不停止
      cursor: null,
    },
  }),
  methods: {
    closeView (val) {
      this.showView = false;
    },
  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>
.sec2 {
  margin-top: 60px;
}

.section-business{
  background: #F9FAFD;
}
</style>
