<template>
    <div id="my-cjwt">

        <div class="" v-loading="loading" :style="{'padding': pagetop+'px' + ' ' + pageleft+'px'}">

            <!-- 列表部分 -->
            <div>
                <div v-if="!!tableData && tableData.length > 0 ">
                    <div v-for="(item,i) in tableData" :key="i">
                        <question-list-item :data="item" :key="item.guid"></question-list-item>

                        <div class="outclass"></div>
                    </div>
                </div>

                <div v-else class="no-data">
                    <el-empty description="暂无数据"></el-empty>
                </div>

                <div class="my-pag" v-show="!!tableData && tableData.length > 0">
                    <my-pagination ref="page" v-model="tableData" :search.sync="pgData" :pagesize="pgData.pagesize"
                        :action="`${this.$store.state.api.webUrl}/index/information/questionPages.json`"
                        :alltotal.sync="total" :loading.sync="loading">
                    </my-pagination>
                </div>

            </div>

        </div>

    </div>
</template>

<script>
import questionListItem from '@/components/sys/question/list-item.vue';

export default {
    name: '',
    layout: 'cjwt',
    components: {
        questionListItem
    },
    data () {
        return {
            loading: false,

            dialogVisible: false,
            dialogTitle: '',

            pgData: {
                point: '',
                // jyptcode: window.$_plat_config.common.name_plan_jc || '',
                jyptcode: '',
                pageindex: 1,
                pagesize: 2,
                sortorder: 'desc',
                sortname: 'addtime',
            },
            total: 0,  //条数
            tableData: [],
            pageleft: 0,   //页面左右边距
            pagetop: 0,    //页面上下边距
        };
    },
    created () { },

    mounted () {
        var that = this;

        // 初始化 
        this.pgData.point = this.getQuery('point');
        this.pgData.jyptcode = this.getQuery('jyptcode');
        this.pgData.pagesize = this.getQuery('pagesize') || 5;
        this.pgData.pagesize = this.pgData.pagesize - 0;
        // this.pageleft = this.getQuery('pageleft') || 0;
        // this.pagetop = this.getQuery('pagetop') || 0;

        //判断是否是顶级页面
        if (window !== window.parent) {
            // 页面被嵌套在iframe中
            console.log("This page is inside an iframe.");
        } else {
            // 页面不是被嵌套在iframe中
            console.log("This page is not inside an iframe.");
            this.pageleft = 30 ;
        }


        this.dialogTitle = decodeURIComponent(this.getQuery('dialogTitle')) || '常见问题';

        if (!this.getQuery('jyptcode')) {
            return ShowMsgBox('平台标识不能为空', 'info');
        }
        this.openDialog();

    },
    methods: {
        openDialog () {
            if (!this.getQuery('jyptcode')) {
                console.log('平台标识不能为空');
                return;
            }

            if (this.$refs.page) {
                this.$refs.page.queryData();
            }

            // this.$nextTick(() => {
            //     this.$refs.page.queryData();
            // });
        },


    },

    beforeDestroy () {
        // 复原本页面的 最小宽度
        var element = document.querySelector('#__layout');
        element.style.minWidth = '1200px';
    },
}
</script>

<style lang="scss" scoped>
.my-cjwt {
    min-width: 500px;
}
.no-data {
    width: 300px;
    height: 300px;
    margin: 0 auto;
}
</style>
