<template>
    <div class="my-question">
        <!-- <div class="title" @click="openDialog">{{dialogTitle}}</div> -->

        <!-- 弹窗 -->
        <my-dialog v-model="dialogVisible" :title="dialogTitle" :width="dialogWidth">
            <iframe class="my-ifr" :src="iframeUrl" frameborder="0" :key="pagePath"
                :style="{width: 'calc(100% + 40px)' , height: dialogHeight, 'margin': '-20px -20px 0', }" allowfullscreen="true"
                webkitallowfullscreen="true" mozallowfullscreen="true"></iframe>
        </my-dialog>

    </div>
</template>

<script>
import CONFIG from '@config/config';

export default {
    name: '',
    props: {
        // 平台标识     必填
        // 默认 使用 window.$_plat_config.common.name_plan_jc 获取(可根据具体项目调整)，获取不到请手动传入。
        jyptcode: {
            type: String,
            default: CONFIG.head.planname,
        },
        // 嵌入位置     必填
        point: {
            type: String,
            default: '',
        },

        // 标题
        dialogTitle: {
            type: String,
            default: '常见问题',
        },
        // 弹窗宽度
        dialogWidth: {
            type: String,
            default: '900px',
        },
        // 弹窗高度
        dialogHeight: {
            type: String,
            default: '600px',
        },

        // 每页条数
        pagesize: {
            type: String,
            default: '5',
        },
    },

    computed: {
        pagePath () {
            return this.$route.path;
        },
        sendPoint () {
            if (!this.pagePath) {
                return;
            }

            let newPoint = '';
            this.typeList.forEach(item => {
                if ( this.pagePath.includes(item.path)) {
                    newPoint = item.point;
                }
            });

            return newPoint;
        },

        iframeUrl () {
            // point 嵌入位置
            // jyptcode 平台标识
            // pagesize 每页条数

            return `/outside-cjwt/index.html?point=${this.sendPoint}&jyptcode=${this.jyptcode}&pagesize=${this.pagesize}`;
        },
    },
    data () {
        return {
            dialogVisible: false,
            // jyptcode: window.$_plat_config.common.name_plan_jc || '',

            typeList: [
                { path: '/register', point: 'regist', },
                { path: '/login', point: 'login', },
            ],
        };
    },
    mounted () {

    },

    methods: {

        openDialog () {
            this.dialogVisible = true;
        },
    },
}
</script>

<style lang="scss" scoped>
.my-question {
    width: 0;
    height: 0;

    // .title {
    //     color: #409eff;
    //     cursor: pointer;
    //     // text-align: right;
    //     font-size: 14px;
    // }
    .dialog-cont {
        margin-top: -30px;
    }
    .my-ifr {
        width: 100%;
        height: 100%;
    }
}
</style> 
