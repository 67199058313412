<template>
    <div class="about content">
        <div class="bread">
            <bread :data="[{path: '/about', title: '关于我们'}]"></bread>
        </div>

        <card class="types">
            <!-- <span slot="title">
                关于我们
            </span> -->
            
            <tab-btn
                :data="types"
                v-model="type"
            ></tab-btn>
        </card>

        <card class="main-con">
            <div 
                v-if="type=='about'"
                class="rich-text" 
                v-html="richPreHandler(detail.infocontent)"
            ></div>

            <div
                v-if="type=='falv'">
                    <p class="data-copyright">
                        本网网站发布的信息和数据版权归宁夏惠泽招科技发展有限公司公司所有，未经宁夏惠泽招科技发展有限公司公司的书面许可，对于本网网站上的任何内容，任何人不得复制或在非本网所属的服务器上做镜像。对非法窃取我网站信息并用于商业用途，及冒充我网站及网站工作人员与您联系并谋求利益者，我司将通过法律手段进行严惩！
                    </p>
            </div>
        </card>
    </div>
</template>

<script>
import Card from '@components/sys/card';
import List from '@components/sys/list';
import Bread from '@components/sys/bread';
import TabBtn from '@components/sys/tab-btn';

import CONFIG from '@config/config';

export default {
    components: {
        Card,
        List,
        Bread,
        TabBtn,
    },
    data: () => ({
        type: 'about',
        pgData: {
            categoryid: '105',
            sortorder: 'desc',
            sortname: 'infodate',
            featurecode:CONFIG.head.planname,
            pagesize:10,
            pageindex:'1'
        },

        detail: '',

        types:[
            {
                value:'about',
                label:'关于我们'
            },{
                value:'falv',
                label:'法律声明'
            },
        ]
    }),
    methods: {
        queryAbout() {
            var that = this;
            that.$get(`${this.$store.state.api.webUrl}/index/information/getwebinfopg.json`,this.pgData, data => {
                if(window.location.href.split(":")[0]=='https'){
                    that.$get(`${this.$store.state.api.webUrl}/index/information/getwebinfodetail.json`, {
                        infoid: '-'+data.rows[0].infoid,
                    }, data => {
                        that.detail = data;
                    })
                }else{
                    that.$get(`${this.$store.state.api.webUrl}/index/information/getwebinfodetail.json`, {
                        infoid: data.rows[0].infoid,
                    }, data => {
                        that.detail = data;
                    })
                }
            });
        },
        richPreHandler(html) {
            if (!html) return '';

            var res = html;

            res = res.replace(/&nbsp;/g, '');

            return res;
        },
        
    },
    mounted() {
        this.queryAbout();
    },
}
</script>

<style lang="scss" scoped>
@import '@css/var.scss';

.about{
    margin-bottom: 26px;

    .types{
        margin-bottom: 12px;

        .type-sel{
            overflow: hidden;

            .item{
                float: left;
                font-size: 16px;
                line-height: 1em;
                padding: 8px 10px;
                border-radius: 18px;
                border: 1px solid #d6d6d6;
                cursor: pointer;

                & + .item{
                    margin-left: 1em;
                }

                &.active{
                    color: white;
                    background: $primaryColor;
                    border-color: $primaryColor;
                }
            }
        }
    }

    .main-con{
        min-height: 700px;
    }

    .page{
        margin-top: 1em;
    }
}

.data-copyright{
    text-indent:2em;
    line-height:28px;
}
</style>
