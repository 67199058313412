<template>
  <div class="topContent">
    <div class="content lunbo clear">
      <section class="banner">
        <el-carousel :interval="10000"
                     class="banner_img"
                     trigger="click">
          <el-carousel-item v-for="(item,index) in bannerList"
                            :key="index">
            <a :href="item.linkurl">
              <img :src="item.linkimg">
            </a>
          </el-carousel-item>
        </el-carousel>
      </section>
      <div class="newsbox">
        <div class="news">
          <div class="head">
            <ul class="tab-btn">
              <!-- <li class="item"
                  :class="{'active': item.typecode === pgData.categoryid}"
                  v-for="(item, index) in navlist"
                  :key="index"
                  @click="newclick(item)">
                {{item.typename}}
              </li> -->
              <li>新闻公告</li>
            </ul>

            <el-link slot="title-r"
                     :underline="false"
                     type="primary"
                     href="/news">
              更多 >
            </el-link>
            <!-- <list
                :data="tableData"
                :props="{title:'title', time:'infodate'}"
                v-loading.sync="loadingController"
                :href="hrefBuilder"
            ></list> -->
          </div>
          <div class="list">
            <a class="item"
               v-for="(item, index) in newlist"
               :key="index"
               :href="hrefBuilder(item)">
              <i></i>
              <div class="addtime">
                {{item.infodate}}
              </div>

              <div class="title">
                <slot :row="item">
                  {{item.title}}
                </slot>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- 登录们 -->
    <div class="content loginreg">
      <!-- <div class="item"
           v-for="(item,index) in loginList"
           :key="index"
           @click="login"
           :class="item.class">
        <p>{{item.name}}</p>
        <p>{{item.logintext}}</p>
      </div> -->
      <a class="item"
         v-for="(item,index) in loginList"
         :key="index"
         :href="item.url"
         :class="item.class">
        <p>{{item.logintext}}</p>
      </a>
      <a class="item zc"
         href="/register">
        <p>注册</p>
      </a>
    </div>
  </div>

</template>

<script>
import CONFIG from '@config/config';
import List from '@components/sys/list';
import Card from '@components/sys/card';
import appNode from '@js/app-node';

export default {
  components: {
    List,
    Card,
  },
  data () {
    return {
      head: CONFIG.head,

      hotList: ['政府采购', '企业采购'],
      bannerList: [],
      dataList: [],
      loginList: [
        {
          logintext: '政府采购项目登录',
          class: 'zfcg',
          url:'/login/index-zfcg'
        }, {
          logintext: '工程建设项目登录',
          class: 'gcjs',
          url:'/login'
        }, {
          logintext: '企业采购项目登录',
          class: 'qycg',
          url:'/login'
        }
      ],
      // 携带的接口参数
      tableData: [],
      pgData: {
        categoryid: '100100',
        sortorder: 'desc',
        sortname: 'infodate',
        featurecode: CONFIG.head.planname,
        pageindex: '1',
        // pagesize: 10,
        pagesize: 7,
      },
      loadingController: false,
      navlist: [
        {
          typename: '通知公告',
          typecode: '100100',
        }, {
          typename: '政策法规',
          typecode: '100101',
        }, {
          typename: '行业资讯',
          typecode: '100103',
        },
      ],
      newlist: [],
    };
  },
  methods: {
    searchHandler () {
      this.goto({
        path: '/business',
        query: {
          searchcontent: this.searchText,
        },
      })
    },
    
    // ---复制news方法
    hrefBuilder (item) {
      var search = toSearch({
        infoid: item.infoid,
      });

      return `/news/detail${search}`;
    },
    newclick (item) {
      this.pgData.categoryid = item.typecode;
      this.pglist()
    },
    pglist () {
      var that = this;
      this.$get(`${this.$store.state.api.webUrl}/index/information/getwebinfopg.json`, this.pgData, data => {
        data.rows.map(r=>{
            if(r.infodate){
                r.infodate = r.infodate.split(' ')[0]
            }
        })
        that.newlist = data.rows;
        // console.log(that.newlist)
      });
    }
  },
  mounted () {
    this.pglist();
    var that = this;


    this.$get(`${this.$store.state.api.webUrl}/index/information/indexinfoextra.json`, {
      featurecode: CONFIG.head.planname,
    }, data => {
      that.dataList = [{
        title: '采购公告',
        num: data.jinrijiaoyi.caigougonggaocount,
      },
      {
        title: '开标',
        num: data.jinrijiaoyi.kaibiaocount,
      },
      {
        title: '评标',
        num: data.jinrijiaoyi.pingbiaocount,
      },
      {
        title: '中标',
        num: data.jinrijiaoyi.zhongbiaocount,
      }];
    });

    
    // 获取轮播图 index/information/getcarouselpic.json
    this.$get(`${this.$store.state.api.webUrl}/index/information/getcarouselpic.json`, {
      featurecode: CONFIG.head.planname,
    }, data => {
      if(data.length>0){
            for (var i = 0; i < data.length; i++) {
                if(window.location.href.split(":")[0]=='https'){
                    if (data[i].linkimg.split(":")[0] == "http") {
                        data[i].linkimg = "https:"+ window.location.href.split(":")[1] + data[i].linkimg.split("20006")[1];
                        that.bannerList = data;
                    }
                }else{
                    if (data[i].linkimg.split(":")[0] == "http") {
                        data[i].linkimg = data[i].linkimg;
                        that.bannerList = data;
                    } else {
                        data[i].linkimg = "http:"+ window.location.href.split(":")[1] + data[i].linkimg;
                        that.bannerList = data;
                    }
                }
                
            }
            this.bannerList = data;
        }else{
            this.bannerList = [{
                linkimg:'/images/banner1.png',
                linkurl:''
            }]
        }
    })
  },
};
</script>

<style lang="scss" scoped>
@import "@css/var.scss";
//  清除浮动
.clear::after {
  display: block;
  content: "";
  clear: both;
}
.topContent {
  background: #ffffff;
  padding-top: 36px;

  .banner {
    box-sizing: border-box;
    width: 585px;
    background-size: cover;
    background-position: center cetner;
    position: relative;
    float: left;
    margin-bottom: 50px;

    .banner_img {
      width: 585px;
      height: 362px;

      ::v-deep .el-carousel__container {
        height: 362px;
      }
      img {
        width: 100%;
        height: 100%;
      }
      // 圆点
      ::v-deep .el-carousel__button {
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }
    }
  }
}
// 复制过来的news
.newsbox .news {
  height: 100%;
  .head {
    height: 60px;
    line-height: 60px;
    font-size: 24px;
    color: #333333;
    padding: 0;
    background: none;
    margin-bottom: 10px;
  }

  .tab-btn {
    overflow: hidden;
    width: 70%;
    float: left;
    .item {
      float: left;
      color: #333333;
      font-size: 22px;
      margin-right: 40px;
      cursor: pointer;
    }
    .active {
      color: #267abc;
      border-bottom: 1px solid $primaryColor;
    }
  }
  .el-link {
    float: right;
    font-size: 16px;
    color: #666666;
    display: none;
  }
  .list {
    min-height: 260px;
    .item {
      overflow: hidden;
      color: #666666;
      display: block;
      margin-bottom: 30px;
      line-height: 1em;
      font-size: 14px;
      i {
        float: left;
        width: 6px;
        height: 6px;
        border-radius: 100%;
        position: relative;
        top: 4px;
        background: #666666;
        margin-right: 12px;
      }
      .title {
        float: left;
        width: 400px;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space:nowrap;
      }
      .addtime {
        float: right;
      }
      // a悬浮 变色
      &:hover {
        color: $primaryColor;
      }
    }
  }
}
// newsbox样式
.lunbo {
  position: relative;
}
.newsbox {
  // background: pink;
  width: 591px;
  height: 362px;
  position: absolute;
  right: 0;
}
.topContent .content {
  overflow: initial;
}
// 登录样式-复制
.loginreg {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  .item {
    display: inline-block;
    width: 280px;
    height: 120px;
    background-color: yellow;
    margin-right: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: 0.3s;
    &:hover {
      transform: translateY(-10px);
    }
    &:last-child {
      margin-right: 0;
    }
    p {
      cursor: pointer;
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #ffffff;
      line-height: 30px;
    }
  }
  .zfcg {
    background: url("../../../static/imgs/zfcg.png");
  }
  .gcjs {
    background: url("../../../static/imgs/gcjs.png");
  }
  .qycg {
    background: url("../../../static/imgs/qycg.png");
  }
  .zc {
    background: url("../../../static/imgs/zc.png");
  }
}
</style>