<template>
    <div class="file-download">
        <!-- 招标文件下载 -->
        <div v-if="data.zbfilelist">
            <h1 class="liehh" v-if="data.zbfilelist.length">招标文件：</h1>
            <div v-if="data.zbfilelist.length" class="download-tip">
                1.招标文件获取期限结束后将不能下载招标文件，请在下载完成后及时使用“投标文件制作工具”打开hzb格式招标文件，可查看招标文件正文即为完整有效。
                <br/>
                2.需妥善保存好验证过的“有效hzb格式招标文件”，没有“有效hzb格式招标文件”将无法制作投标文件。
                <br/>
                3.全流程电子标.hzb格式招标文件请务必下载，否则将无法制作投标文件。
            </div>
            <table class="ggao_tab" cellpadding="0" cellspacing="0" v-if="data.zbfilelist.length">
                <tr>
                    <th class="ggao_tabthd">招标文件名称</th>
                    <th class="ggao_tabthd">标段名称</th>
                    <th class="ggao_tabthd">HZB版下载</th>
                    <th class="ggao_tabthd">PDF版下载</th>
                    <th class="ggao_tabthd">其他下载</th>
                    <!-- <th class="ggao_tabthd">操作</th> -->
                </tr>
                <tr v-for="(v,i) in data.zbfilelist" :key="i">
                    <td class="ggao_tabtrd">
                        <p class="mt-15">
                            {{v.zbfilename}}
                            <span v-if="v.hascq && v.wjlx=='wj'" class="c-red">（请及时获取最新澄清文件）</span>
                        </p>
                    </td>
                    <td class="ggao_tabtrd"><p class="mt-15">{{v.bdname}}</p></td>
                    <td class="ggao_tabtrd">
                        <!-- HZB版下载 -->
                        <div>
                            <template v-if="!v.isend && v.isbegin && v.hasyc !== '1'">
                                <a 
                                :href="`${$srore.state.api.fileUrl}/download/tender/hzb.do?rowguid=${v.rowguid}`" 
                                class="c-rose"
                                >
                                    下载
                                </a>

                                <p class="c-gray">{{v.filesize}}MB</p>

                                <p class="c-gray">{{v.filesizekb}}KB</p>
                            </template>

                            <span 
                            v-if="(v.isend || !v.isbegin) && v.hasyc !== '1'"
                            >
                                该招标文件未在下载期限<p class="c-gray">{{v.filesize}}MB</p>

                                <p class="c-gray">{{v.filesizekb}}KB</p>
                            </span>

                            <span v-if="v.hasyc === '1'">
                                该标段已发布异常终止
                            </span>
                        </div>
                    </td>
                    <td class="ggao_tabtrd">
                        <!-- PDF版下载 -->
                        <div>
                            <a 
                            v-if="!v.isend && v.isbegin && v.hasyc !== '1'" 
                            :href="`${$srore.state.api.fileUrl}/download/tender/pdf.do?rowguid=${v.rowguid}`" 
                            class="c-rose"
                            >
                                下载
                            </a>

                            <span 
                            v-if="(v.isend || !v.isbegin) && v.hasyc !== '1'"
                            >
                                该招标文件未在下载期限
                            </span>

                            <span v-if="v.hasyc === '1'">
                                该标段已发布异常终止
                            </span>
                        </div>
                    </td>
                    <td class="ggao_tabtrd">
                        <!-- 其他下载 -->
                        <div>
                            <span v-if="!v.qtfj && v.hasyc !== '1'">无其他文件</span>
                            <div v-if="v.qtfj && v.hasyc !== '1'">
                                <p v-for="(item, index) in v.qtfjlist" :key="index">
                                    <a :href="`${$srore.state.api.fileUrl}/download/common.do?rowguid=${item.rowguid}`">{{item.filename}}</a>
                                </p>
                            </div>
                            <span v-if="v.hasyc === '1'">
                                该标段已发布异常终止
                            </span>
                        </div>
                    </td>
                </tr>
            </table>
        </div>

        <!-- 澄清文件下载 -->
        <div v-if="data.cqfilelist">
            <h1 class="liehh" v-if="data.cqfilelist.length">澄清文件：</h1>
            <div v-if="data.cqfilelist.length"  class="tip-cqfile">
                1.招标文件获取期限结束后将不能下载招标文件，请在下载完成后及时使用“投标文件制作工具”打开hzb格式招标文件，可查看招标文件正文即为完整有效。
                <br/>
                2.需妥善保存好验证过的“有效hzb格式招标文件”，没有“有效hzb格式招标文件”将无法制作投标文件。
            </div>
            <table class="ggao_tab" cellpadding="0" cellspacing="0" v-if="data.cqfilelist.length">
                <tr>
                    <th class="ggao_tabthd">招标文件名称</th>
                    <th class="ggao_tabthd">标段名称</th>
                    <th class="ggao_tabthd">HZB版下载</th>
                    <th class="ggao_tabthd">PDF版下载</th>
                    <th class="ggao_tabthd">其他下载</th>
                    <!-- <th class="ggao_tabthd">操作</th> -->
                </tr>
                <tr v-for="(v,i) in data.cqfilelist" :key="i">
                    <td class="ggao_tabtrd"><p class="mt-15">{{v.zbfilename}}</p></td>
                    <td class="ggao_tabtrd"><p class="mt-15">{{v.bdname}}</p></td>
                    <td class="ggao_tabtrd">
                        <!-- HZB版下载 -->
                        <div>
                            <template v-if="!v.isend && v.isbegin && v.hasyc !== '1'">
                                <a 
                                :href="`${$srore.state.api.fileUrl}/download/tender/hzb.do?rowguid=${v.rowguid}`"
                                class="c-rose"
                                >
                                下载
                                </a>

                                <p class="c-gray">{{v.filesize}}MB</p>
                                <p class="c-gray">{{v.filesizekb}}KB</p>
                            </template>

                            <span v-if="(v.isend || !v.isbegin) && v.hasyc !== '1'">
                                该澄清文件未在下载期限
                                <p class="c-gray">{{v.filesize}}MB</p>
                                <p class="c-gray">{{v.filesizekb}}KB</p>
                            </span>

                            <span v-if="v.hasyc === '1'">
                                该标段已发布异常终止
                            </span>
                        </div>
                    </td>
                    <td class="ggao_tabtrd">
                        <!-- PDF版下载 -->
                        <div>
                            <a 
                            v-if="!v.isend && v.isbegin && v.hasyc !== '1'"
                            :href="`${$srore.state.api.fileUrl}/download/tender/pdf.do?rowguid=${v.rowguid}`"
                            class="c-rose"
                            >
                                下载
                            </a>

                            <span v-if="(v.isend || !v.isbegin) && v.hasyc !== '1'">该澄清文件未在下载期限</span>

                            <span v-if="v.hasyc === '1'">
                                该标段已发布异常终止
                            </span>
                        </div>
                    </td>
                    <td class="ggao_tabtrd">
                        <!-- 其他下载 -->
                        <div>
                            <span v-if="!v.qtfj && v.hasyc !== '1'">无其他文件</span>

                            <div v-if="v.qtfj && v.hasyc !== '1'">
                                <p v-for="(item, index) in v.qtfjlist" :key="index">
                                    <a :href="`${$srore.state.api.fileUrl}/download/common.do?rowguid=${item.rowguid}`">{{item.filename}}</a>
                                </p>
                            </div>

                            <span v-if="v.hasyc === '1'">
                                该标段已发布异常终止
                            </span>
                        </div>
                    </td>
                </tr>
            </table>
        </div>

        <!-- 补遗文件下载 -->
        <div v-if="data.byfilelist">
            <h1 class="liehh" v-if="data.byfilelist.length">补遗文件：</h1>
            <table class="ggao_tab" cellpadding="0" cellspacing="0" v-if="data.byfilelist.length">
                <tr>
                    <th class="ggao_tabthd">招标文件名称</th>
                    <th class="ggao_tabthd">标段名称</th>
                    <th class="ggao_tabthd">文件下载</th>
                    <th class="ggao_tabthd">其他下载</th>
                    <!-- <th class="ggao_tabthd">操作</th> -->
                </tr>
                <tr v-for="(v,i) in data.byfilelist" :key="i">
                    <td class="ggao_tabtrd"><p class="mt-15">{{v.zbfilename}}</p></td>
                    <td class="ggao_tabtrd"><p class="mt-15">{{v.bdname}}</p></td>
                    <td class="ggao_tabtrd">
                        <p>同招标文件（澄清文件）</p>
                    </td>
                    <td class="ggao_tabtrd">
                        <!-- 其他下载 -->
                        <div>
                            <span v-if="(!v.qtfj) && v.hasyc !== '1'">无其他文件</span>

                            <div v-if="(v.qtfj) && v.hasyc !== '1'">
                                <p v-for="(item, index) in v.qtfjlist" class="wjdown" :key="index">
                                    <a :href="`${$srore.state.api.fileUrl}/download/common.do?rowguid=${item.rowguid}`">{{item.filename}}</a>
                                </p>
                            </div>

                            <span v-if="v.hasyc === '1'">
                                该标段已发布异常终止
                            </span>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
    },
};
</script>

<style lang="scss" scoped>
    $tableBorder: 1px #dfdfdf solid;

    table {
        width: 100%;
        border-top: $tableBorder;
        border-left: $tableBorder;
        margin-bottom: 1em;
        margin: 1em 0;

        td{
            border-right: $tableBorder;
            border-bottom: $tableBorder;
            padding: 1em;
        }
    }

    .download-tip{
        width:92%;
        color:red;
        margin:0 auto;
    }

    .mt-15{
        margin-top: 15px;
    }

    .c-red{
        color: red;
    }

    .c-gray{
        color: #999;
    }

    .c-rose{
        color: #eb5c5c;
    }

    .tip-cqfile{
        width:92%;
        color:red;
        margin:0 auto;
    }
</style>