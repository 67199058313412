<template>
    <nuxt/>
</template>

<script>
export default {
    methods: {
        
    }
}
</script>

<style lang="scss" scoped>
    #__layout{min-width: 500px; min-height:100%;}
</style>