<template>
    <div class="news-detail content">
        <div class="bread">
            <bread :data="bread"></bread>
        </div>

        <card class="main-con">
            <div 
                class="context rich-text" 
                v-html="detail.infocontent"
            ></div>
            <div v-if="fileList.length!=0">
                <p>附件：</p>
                <my-upload :file-guid="detail.fileguid" readonly></my-upload>
            </div>
        </card>
    </div>
</template>

<script>
import Card from '@components/sys/card';
import Bread from '@components/sys/bread';

export default {
    components: {
        Card,
        Bread,
    },
    data: () => ({
        bread: [{path: '/news', title: '新闻公告'}],

        detail: {},
        fileList:[],
    }),
    methods: {
        queryDetail() {
            var that = this;
            this.$get(`${this.$store.state.api.webUrl}/index/information/getwebinfodetail.json`, {
                infoid: this.getQuery('infoid'),
            }, data => {
                this.detail = data;

                this.bread.push({
                    path: this.$route.fullPath,
                    title: data.title,
                })
                if(that.detail.fileguid){
                    that.$ajax({
                        url: `${that.$store.state.api.fileUrl}/operate/getlist.json`,
                        data:{
                            fileguid: that.detail.fileguid
                        },
                        callback: data => {
                            that.fileList = data || [];
                        },
                    });
                }
                
            });
        },
    },
    mounted() {
        this.queryDetail();
    }
}
</script>

<style lang="scss" scoped>
@import '@css/var.scss';

.news-detail{
    margin-bottom: 26px;

    .main-con{
        min-height: 600px;
    }

    .context{
        line-height: 2em;
        color: #262626;
    }
}
</style>
