<template>
  <div class="news">
    <card class="policy">

      <span slot="title"
            class="titlespan">
        <i></i>政策法规
      </span>

      <!-- <el-link slot="title-r"
               :underline="false"
               type="primary"
               href="/news">
        更多>
      </el-link> -->
      <el-link slot="title-r"
               :underline="false"
               type="primary"
               @click="topolicy">
        更多>
      </el-link>
      <div class="list">
        <a class="item"
           v-for="(item, index) in policylist"
           :key="index"
           :href="hrefBuilder(item)">

          <div class="title">
            <slot :row="item">
              {{item.title}}
            </slot>
          </div>
        </a>
      </div>
    </card>
    <card class="field">

      <span slot="title"
            class="titlespan">
        <i></i>行业资讯
      </span>

      <el-link slot="title-r"
               :underline="false"
               type="primary"
               @click="tofield">
        <!-- /buy?type=${item.type} -->
        更多>
      </el-link>
      <div class="list">
        <a class="item"
           v-for="(item, index) in fieldlist"
           :key="index"
           :href="hrefBuilder(item)">

          <div class="title">
            <slot :row="item">
              {{item.title}}
            </slot>
          </div>
        </a>
      </div>
    </card>
    <!-- <div class="head">
      <ul class="tab-btn">
        <li class="item"
            :class="{'active': item.typecode === pgData.categoryid}"
            v-for="(item, index) in navlist"
            :key="index"
            @click="newclick(item)">
          {{item.typename}}
        </li>
      </ul>

      <el-link slot="title-r"
               :underline="false"
               type="primary"
               href="/news">
        更多 >
      </el-link>
      
      <list
          :data="tableData"
          :props="{title:'title', time:'infodate'}"
          v-loading.sync="loadingController"
          :href="hrefBuilder"
            ></list>
    </div>
    <div class="list">
      <a class="item"
         v-for="(item, index) in newlist"
         :key="index"
         :href="hrefBuilder(item)">
        <i></i>
        <div class="addtime">
          {{item.infodate}}
        </div>

        <div class="title">
          <slot :row="item">
            {{item.title}}
          </slot>
        </div>
      </a>
    </div> -->
  </div>

</template>

<script>
import CONFIG from '@config/config';

import List from '@components/sys/list';
import Card from '@components/sys/card';

export default {
  components: {
    List,
    Card,
  },
  data () {
    return {
      tableData: [],
      pgData: {
        // 政策法规categoryid100101 
        categoryid: '100101',
        sortorder: 'desc',
        sortname: 'infodate',
        featurecode: CONFIG.head.planname,
        pageindex: '1',
        // pagesize: 10,
        pagesize: 8
      },
      loadingController: false,
      navlist: [
        {
          typename: '通知公告',
          typecode: '100100',
        }, {
          typename: '政策法规',
          typecode: '100101',
        }, {
          typename: '行业资讯',
          typecode: '100103',
        },
      ],
      newlist: [],
      // -----------
      // 政策数组 固定参数返回的值
      policylist: [],
      // 行业资讯数组 固定参数返回的值
      fieldlist: [],
    };
  },
  methods: {

    hrefBuilder (item) {
      var search = toSearch({
        infoid: item.infoid,
      });

      return `/news/detail${search}`;
    },
    // newclick (item) {
    //   this.pgData.categoryid = item.typecode;
    //   this.pglist()
    // },
    // 不需要点击切换 直接展示
    pglistpolicy () {
      var that = this;
      this.$get(`${this.$store.state.api.webUrl}/index/information/getwebinfopg.json`, this.pgData, data => {
        that.policylist = data.rows;
        // console.log(this.policylist)
      });

    },
    pglistfield () {
      var that = this;
      // 行业资讯categoryid100101 
      that.pgData.categoryid = '100103';
      this.$get(`${this.$store.state.api.webUrl}/index/information/getwebinfopg.json`, this.pgData, data => {
        that.fieldlist = data.rows;
        // console.log(this.fieldlist)
      });

    },
    // 政策法规携带参数
    topolicy () {
      this.$router.push(
        {
          path: '/news',
          query: {
            type: '100101'
          }
        }
      )
    },
    tofield () {
      this.$router.push(
        {
          path: '/news',
          query: {
            type: '100103'
          }
        }
      )
    }
  },
  mounted () {
    this.pglistpolicy();
    this.pglistfield();
  },
};
</script>

<style lang="scss" scoped>
@import "@css/var.scss";
// 卡片-列表内容部分
.news {
  .list {
    min-height: 260px;
    margin-top: 30px;
    .item {
      overflow: hidden;
      color: #666666;
      display: block;
      line-height: 1em;
      margin-bottom: 24px;
      i {
        float: left;
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background: #016fb8;
        margin-top: 10px;
        margin-right: 10px;
      }
      .title {
        float: left;
        // text-align: left;
      }
      .addtime {
        float: right;
      }
      &:hover {
        color: $primaryColor;
      }
    }
  }
}
// 卡片 复制过来的样式 头部
::v-deep .card {
  padding: 0;
  .head {
    height: 60px;
    line-height: 60px !important;
    padding: 0;
    margin-top: 15px;
    margin-bottom: 0 !important;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e0e0e0;
    .titlespan {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    span {
      color: #333333;
      font-size: 24px;
      font-weight: 400;
      i {
        display: inline-block;
        width: 4px;
        height: 23px;
        background: #247cd6;
        margin-right: 16px;
      }
    }
    .title-r span {
      font-size: 14px;
      color: #999999;
    }
  }
}
// policy样式
.news {
  display: flex;
  justify-content: space-between;
}
.policy,
.field {
  width: 570px;
  height: 445px;
  // background-color: pink;
}
::v-deep .policy .head,
::v-deep .field .head {
  height: 75px;
  line-height: 75px !important;
}
// 列表部分-超出文字隐藏
::v-deep .policy .body .list .item .title,
::v-deep .field .body .list .item .title {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 3em;
}
</style>
