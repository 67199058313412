<template>
    <div class="enters">
    </div>
</template>

<script>
import CONFIG from '@config/config';

import advBbJpg from '../assets/adv-bb.jpg';
import advCaJpg from '../assets/adv-ca.jpg';

import iconGuide from '../assets/icon/guide.png';
import iconTel from '../assets/icon/tel.png';

export default {
    data: () => ({
  
        guide: [
            {
                title: '关于我们',
                icon: iconGuide,
                url: '/about'
            },
            {
                title: '帮助中心',
                icon: iconTel,
                url: '/guide'
            }
        ],
        head:CONFIG.head,
    }),
    methods: {
        
    }
}
</script>

<style lang="scss" scoped>
    @import '@css/var.scss';

    .enters{
        .lr{
            overflow: hidden;

            .item{
                display: block;
                width: 100%;
                box-sizing: border-box;
                float: left;
                font-size:16px;
                color: white;
                text-align: center;
                box-sizing: border-box;
                cursor: pointer;
                transition: all .3s;
                height: 56px;
                line-height: 56px;

                &.zfcg{
                    background:url(../assets/1.png)
                }
                &.gcjs{
                    background:url(../assets/2.png)
                }
                &.jtxm{
                    background:url(../assets/3.png)
                }
                &.slgc{
                    background:url(../assets/4.png)
                }
                &.qycg{
                    background:url(../assets/5.png)
                }

                &.info{
                    background: white;
                    color: #2252e7;
                    border:1px solid #2252e7;

                    &:hover{
                        background: #2252e7;
                        color: white;
                    }
                }
            }
        }


        
    }

    .mt-8{
        margin-top: 8px;
    }
</style>