<template>
  <div class="news content">
    <div class="bread">
      <bread :data="[{path: '/news', title: '新闻公告'}]"></bread>
    </div>

    <card class="types">
      <span slot="title">
        新闻公告
      </span>

      <tab-btn :data="types"
               :props="{label:'typename',value:'typecode'}"
               v-model="pgData.categoryid"
               @select="searchHandler"></tab-btn>
    </card>

    <card class="list"
          v-loading="loadingController">
      <list :data="tableData"
            :props="{title:'title', time:'infodate'}"
            :href="hrefBuilder"></list>

      <my-pagination ref="page"
                     class="page"
                     :action="`${$store.state.api.webUrl}/index/information/getwebinfopg.json`"
                     v-model="tableData"
                     :search="pgData"
                     :afterQuery="afterQuery"
                     :loading.sync="loadingController"></my-pagination>
    </card>
  </div>
</template>

<script>
import CONFIG from '@config/config';

import Card from '@components/sys/card';
import List from '@components/sys/list';
import Bread from '@components/sys/bread';
import TabBtn from '@components/sys/tab-btn';

export default {
  components: {
    Card,
    List,
    Bread,
    TabBtn,
  },
  data () {
    return {
      tableData: [],

      pgData: {
        categoryid: this.getQuery('type') || '100100',
        // categoryid: '100100',
        sortorder: 'desc',
        sortname: 'infodate',
        featurecode: CONFIG.head.planname,
      },

      types: [],

      loadingController: false,
      // 获得点击传过来的categoryid
      receiveid: ''
    }
  },
  methods: {
    afterQuery(data){
        data.map(r=>{
            if(r.infodate){
                r.infodate = r.infodate.split(' ')[0]
            }
        })
    },
    queryWebCateList () {
      this.$get(`${this.$store.state.api.webUrl}/index/information/getwebcatelistbyparentid.json`, {
        parentid: 100,
      }, data => {
        this.types = data;
      });
    },
    searchHandler (item) {
      this.$refs.page.queryData();
    },
    hrefBuilder (item) {
      var search = toSearch({
        infoid: item.infoid,
        featurecode: CONFIG.head.planname
      });

      return `/news/detail${search}`;
    },
  },
  mounted () {
    this.queryWebCateList();
  }
}
</script>

<style lang="scss" scoped>
@import "@css/var.scss";

.news {
  margin-bottom: 26px;

  .types {
    margin-bottom: 12px;

    .type-sel {
      overflow: hidden;

      .item {
        float: left;
        font-size: 16px;
        line-height: 1em;
        padding: 8px 10px;
        border-radius: 18px;
        border: 1px solid #d6d6d6;
        cursor: pointer;

        & + .item {
          margin-left: 1em;
        }

        &.active {
          color: white;
          background: $primaryColor;
          border-color: $primaryColor;
        }
      }
    }
  }

  .list {
    min-height: 500px;
  }

  .page {
    margin-top: 1em;
  }
}
</style>
