<template>
  <header class="header" :class="{ fixed: fixed }">
    <div class="topnav">
      <div class="content">
        <img class="logo" v-if="head.logourl" :src="head.logourl" />
		<div class="kefu tel">
          <img src="/images/dianhua.png" alt="" />
          <div class="text">
            <span>客服电话：</span>
            <p>{{ head.tel }}</p>
          </div>
        </div>
      </div>

      <!-- <div class="head_logo" style="margin-top:20px">
                <a href="/">
                    {{head.title}}
                </a>
            </div> -->
      <div class="bluebg">
        <div class="content nav-list">
          <template v-for="(item, index) in $store.state.config.nav">
            <a
              class="nav-item"
              :key="index"
              :href="item.href"
              :class="{ active: isActive(item) }"
              @click="clickHandler($event, item.click)"
            >
              {{ item.title }}
            </a>
          </template>
        </div>
      </div>

      <!-- <div class="search">
                <el-input v-model="searchText" class="input-search" size="middle">
                    <el-button 
                        placeholder="请输入搜索信息" 
                        slot="append" 
                        @click="searchHandler">
                            搜索
                    </el-button>
                </el-input>
            </div> -->
    </div>
    <!--  
    <div class="businesssearch">
      <el-input placeholder="请输入工程名称"
                v-model="searchText"
                class="input-search"
                style="height:56px">
        <el-button type="primary"
                   style="height:56px"
                   slot="append"
                   @click="searchHandler">
          搜索
        </el-button>
      </el-input>
    </div>
    <div class="content loginreg">
      <div class="item"
           v-for="(item,index) in loginList"
           :key="index"
           @click="login"
           :class="item.class">
        <p>{{item.name}}</p>
        <p>{{item.logintext}}</p>
      </div>
      <div class="item reg">
        <a href="/register"
           target="_blank">
          <p>注册</p>
        </a>
      </div>
    </div>
    -->
    <slot></slot>
  </header>
</template>

<script>
import CONFIG from "@config/config";
import business from "../../../pages/index/sections/business";
import { request } from "http";
import appNode from "@js/app-node";

export default {
  components: {},
  props: {
    theme: {
      type: String,
      default: "",
    },
    fixed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      useSearchDeal: false, //是否使用 (查交易中心、查公告、查企业) 搜索组件
      searchText: "",
      head: CONFIG.head,
      loginList: [
        {
          name: "政府采购",
          logintext: "项目登录",
          class: "zfcg",
        },
        {
          name: "工程建设",
          logintext: "项目登录",
          class: "gcjs",
        },
        {
          name: "企业采购",
          logintext: "项目登录",
          class: "qycg",
        },
      ],
    };
  },
  computed: {},
  methods: {
    isActive(item) {
      var path = this.$route.path,
        href = item.href,
        activeReg = item.activeReg;

      if (href === path) {
        return true;
      }

      if (path === href) {
        return true;
      }

      if (activeReg && activeReg.test(path)) {
        return true;
      }

      return false;
    },
    clickHandler(e, cb) {
      if (cb) {
        e.preventDefault();

        var fun = new Function(cb);
        fun.call(this);
      }
    },
    // 搜索
    searchHandler() {
      this.goto({
        path: "/business",
        query: {
          searchcontent: this.searchText,
        },
      });
    },
    // 登录
    login() {
      window.open(
        "/biddingWeb/pages/chooseusersub.html?indexUrl=" +
          window.btoa(window.location.href) +
          "&title=" +
          escape(this.head.title) +
          "&plan=" +
          CONFIG.head.planname
      );
    },
  },
  mounted: function () {},
  created() {},
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@css/var.scss";

.header {
  cursor: default;
  height: 256px;
  // box-shadow: 0px 1px 6px 0px rgba(61, 126, 255, 0.15);

  &.fixed {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10000;
    min-width: 1200px;
  }

  .header-com {
    float: left;
  }

  img.logo {
    width: 578px;
    height: 86px;
    margin-top: 57px;
  }

  .nav-list {
    font-size: 20px;
    overflow: hidden;
    .nav-item {
      float: left;
      text-align: center;
      width: 142px;
      height: 56px;
      cursor: pointer;
      line-height: 56px;
      font-size: 20px;
      color: #ffffff;

      &:hover {
        color: #ffffff;
        background: #005dbc;
      }

      & + .nav-item {
        margin-left: 30px;
      }

      &.active {
        color: #ffffff;
        background: #005dbc;
        // &:after{
        //     content: '';
        //     display: block;
        //     width: 100%;
        //     height: 4px;
        //     position: absolute;
        //     left: 0;
        //     bottom: -23px;
        //     background-color: $primaryColor;
        // }
      }
    }
  }

  .head_logo {
    margin-top: 15px;
    min-width: 130px;
    float: left;
    font-size: 22px;
    font-weight: 700;
  }
  .head_logo a {
    color: #016fb8;
  }
  // .search{
  //     float:right;
  //     margin-top: 25px;
  //     ::v-deep .el-input__inner{ height: 30px; }
  //     ::v-deep .el-button{
  //         background: #2252e7;
  //         color: #fff;
  //         padding: 8px 20px;
  //         border-top-left-radius: 0;
  //         border-bottom-left-radius: 0;
  //     }
  // }
}

.child-item {
  color: #333333;
}

// 顶部导航
.topnav {
  width: 100%;
  height: auto;
  background: url("../../../static/imgs/top_bg.png");
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}
.bluebg {
  margin-top: 54px;
  width: 100%;
  height: 56px;
  background: #247cd6;
  z-index: 2;
  position: relative;
}
.kefu{
	margin-right: 10px;
	float: right;
	overflow: hidden;
	img{
		float: left;
		margin-top: 15px;
		margin-right: 20px;
		width: 42px;
	}
	div{
		font-size: 24px;
		font-weight: bold;
	}
	p{
		margin-top: 5px;
	}
}

.tel{
  float: right; 
  margin-top: 70px;

  .text{
    float: left;

    p{
      margin-top: 10px;
    }
  }
}
</style>
