<template>
  <div class="logins">
    <div class="businesssearch">
      <el-input placeholder="请输入公告名称"
                v-model="searchText"
                class="input-search">
        <el-button type="primary"
                   class="search-btn"
                   slot="append"
                   @click="searchHandler">
          搜索
        </el-button>
      </el-input>
    </div>
    <!-- 登录们 -->
    <div class="content loginreg">
      <!-- <div class="item"
           v-for="(item,index) in loginList"
           :key="index"
           @click="login"
           :class="item.class">
        <p>{{item.name}}</p>
        <p>{{item.logintext}}</p>
      </div> -->
      <a class="item"
         v-for="(item,index) in loginList"
         :key="index"
         :class="item.class">
        <p>{{item.name}}</p>
        <p>{{item.logintext}}</p>
      </a>
      <div class="item reg">
        <a href="/register">
          <p>注册</p>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import CONFIG from '@config/config';
export default {
  data () {
    return {
      head: CONFIG.head,
      searchText: '',
      loginList: [
        {
          name: '政府采购',
          logintext: '项目登录',
          class: 'zfcg'
        }, {
          name: '工程建设',
          logintext: '项目登录',
          class: 'gcjs'
        }, {
          name: '企业采购',
          logintext: '项目登录',
          class: 'qycg'
        }
      ]
    }
  },
  methods: {
    // 搜索
    searchHandler () {
      this.goto({
        path: '/business',
        query: {
          searchcontent: this.searchText,
        },
      })
    },
    // 登录
    login () {
      // 跳转到另一个页面、

    },
  }
}
</script>

<style lang="scss" scoped>
.logins {
  // background: url("../../../static/img/banner_bg.jpg");
  // height: 578px;
  // position: relative;
  // top: -100px;
  // z-index: 1;
}
//搜索框样式
.businesssearch {
  width: 800px;
  height: 56px;
  border-radius: 3px;
  margin: 0 auto;
  padding-top: 188px;

  .input-search, search-btn{
    height:56px;
  }

  ::v-deep .input-search .el-input__inner {
    height: 56px !important;
    padding-left: 30px;
    font-size: 18px;
    // color: #999999;
  }
  ::v-deep .input-search input::placeholder {
    font-size: 18px;
    color: #999999;
  }
  ::v-deep .el-button {
    border-radius: 0px 3px 3px 0px;
    color: #ffffff;
    padding: 12px 30px;
    height: 56px;
    background: #247cd6;
    span {
      font-size: 18px;
      color: #ffffff;
    }
  }
}
// 登录样式
.loginreg {
  padding-top: 139px;
  display: flex;
  justify-content: center;
  align-items: center;
  .item {
    display: inline-block;
    width: 200px;
    height: 200px;
    background-color: yellow;
    margin-right: 127px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    transition: 0.3s;
    &:hover {
      transform: translateY(-10px);
    }
    &:last-child {
      margin-right: 0;
      background: #ffffff;
      opacity: 0.8;
      border-radius: 10px;
    }
    p {
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #247cd6;
      line-height: 30px;
      cursor: pointer;
    }
  }
  .zfcg {
    background: url("../../../static/img/zfcg.jpg") -6px -5px;
  }
  .gcjs {
    background: url("../../../static/img/gcjs.jpg") -6px -5px;
  }
  .qycg {
    background: url("../../../static/img/qycg.jpg") -6px -5px;
  }

  .reg p {
    font-size: 24px;
    font-weight: bold;
  }
}
// 顶部导航
.topnav {
  width: 1600px;
  margin: 0 auto;
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}
</style>