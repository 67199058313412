<template>
    <div>
        <div class="fix-box" v-show="!!tableData && tableData.length>0">
            <transition name="el-fade-in-linear">
                <div>
                    <div class="title">常见问题</div>

                    <div class="cont">
                        <div class="item" v-for="(item,i) in tableData" :key="'c' + i + item.guid"
                            @click="showDialog(item)">
                            {{item.title}}
                        </div>

                        <div class="more" @click="gotoMore">查看更多</div>
                    </div>
                </div>
            </transition>
        </div>

        <!-- 弹窗 -->
        <my-dialog v-model="dialogVisible" title="常见问题" width="800px">
            <listItem class="dialog-cont" :data="detailData" :key="'d' +detailData.guid"></listItem>
        </my-dialog>

    </div>
</template>

<script>
import CONFIG from '@config/config';
import listItem from './list-item.vue';


export default {
    name: '',
    components: {
        listItem,
    },
    data () {
        return {
            tableData: [],
            loadingController: false,

            dialogVisible: false,
            detailData: {},

        };
    },

    mounted () {
        this.getList();
    },


    methods: {

        gotoMore () {
            this.goto('/guide');
        },
        showDialog (val) {
            this.detailData = val;
            this.dialogVisible = true;
        },


        getList () {
            var that = this;

            this.loadingController = true;
            // 获取帮助中心接口
            that.$get(`${this.$store.state.api.webUrl}/index/information/headQuestions.json`, {
                featurecode: CONFIG.head.planname,
            }, function (data) {
                that.tableData = data;
                that.loadingController = false;
            });
        },

    },
}
</script>

<style lang="scss" scoped>
@import '@css/var.scss';

.fix-box {
    width: 200px;
    color: #333;
    background-color: #fff;

    position: fixed;
    // top: 300px;
    bottom: 200px;
    right: 8px;
    border: 1px solid #ccc;
    z-index: 10;

    .title {
        padding: 0 10px;
        width: 100%;
        font-size: 16px;
        height: 35px;
        line-height: 35px;
        border-bottom: 1px solid #ccc;
    }

    .cont {
        padding: 0 10px;
        max-height: 270px;
        overflow-y: auto;

        .item {
            font-size: 14px;
            cursor: pointer;

            height: 30px;
            line-height: 30px;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &:hover {
                color: $primaryColor;
            }
        }
    }

    .more {
        padding: 0 10px;
        width: 100%;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
        color: $primaryColor;
        cursor: pointer;
        text-align: center;
    }
}

.dialog-cont {
    margin: -30px -15px;
}
</style>