<template>
    <div class="about content">
        <div class="bread">
            <bread :data="[{path: '/download', title: '下载中心'}]"></bread>
        </div>

        <card class="types">
            <span slot="title">
                下载中心
            </span>

        </card>

        <card class="main-con">

            <div v-if="type === 'download'">
                <el-table :data="tableData" class="table-full" border>
                    <el-table-column type="index" width="50" align="center"></el-table-column>
                    <el-table-column prop="title" label="文件名称" min-width="190"></el-table-column>
                    <el-table-column label="下载" min-width="100">
                        <template slot-scope="scope">
                            <template v-if="scope.row.urlList">
                                <a 
                                v-for="(item,index) in scope.row.urlList" 
                                :key="index"
                                :href="item" 
                                class="color-rose"
                                >
                                    免费下载地址{{index+1}}
                                </a>
                            </template>
                            <template v-else>
                                <a :href="scope.row.urlname" class="color-rose">免费下载</a>
                            </template>
                            
                        </template>
                    </el-table-column>
                    <el-table-column prop="infoa" label="使用范围" min-width="190"></el-table-column>
                </el-table>
            </div>
        </card>
    </div>
</template>

<script>
import CONFIG from '@config/config';

import Card from '@components/sys/card';
import List from '@components/sys/list';
import Bread from '@components/sys/bread';
import TabBtn from '@components/sys/tab-btn';

export default {
    components: {
        Card,
        List,
        Bread,
        TabBtn,
    },
    data: () => ({
        tableData: [],

        type: 'download',

    }),
    methods: {
     
        richPreHandler(html) {
            if (!html) return '';

            var res = html;

            res = res.replace(/&nbsp;/g, '');

            return res;
        },
        queryDownload() {
            this.$get(`${this.$store.state.api.webUrl}/index/information/getmoretooldownload.json`, {
                infoid: 9,
                featurecode:CONFIG.head.planname,
                featureonly:'1'
            }, data => {
                var list = data;

                list.forEach(function(item,index){
                    if(item.by5){
                        if(item.by5.indexOf(',') !=-1){
                            var url = item.by5.split(',');
                            list[index].urlList = url;
                        }
                    }
                });

                this.tableData = list;
            });
        },
    },
    mounted() {
        this.queryDownload();
    },
}
</script>

<style lang="scss" scoped>
@import '@css/var.scss';

.about{
    margin-bottom: 26px;

    .types{
        margin-bottom: 12px;

        .type-sel{
            overflow: hidden;

            .item{
                float: left;
                font-size: 16px;
                line-height: 1em;
                padding: 8px 10px;
                border-radius: 18px;
                border: 1px solid #d6d6d6;
                cursor: pointer;

                & + .item{
                    margin-left: 1em;
                }

                &.active{
                    color: white;
                    background: $primaryColor;
                    border-color: $primaryColor;
                }
            }
        }
    }

    .main-con{
        min-height: 700px;
    }

    .page{
        margin-top: 1em;
    }
}

.table-full{
    width: 100%;
}

.color-rose{
    color: #eb5c5c;
}
</style>
