<template>
    <el-button 
    class="btn-yzm"
    slot="append" 
    :type="type" 
    :disabled="btnDisabled" 
    @click="getYzm"
    :loading="loadingController" 
    >
        {{text}}
    </el-button>
</template>

<script>
import CONFIG from '@config/config';
import MODEL from '@mixins/model'

export default {
    mixins: [ MODEL ],
    props: {
        mobile: {
            type: String,
            default: '',
        },
        captcha: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: 'primary',
        },
    },
    data() {
        return {
            loadingController: false,
            btnDisabled: false,
            text: '获取手机验证码',
        }
    },
    methods: {
        getYzm() {
            if(!this.mobile ||  !/^[1][0-9]{10}$/.test(this.mobile)) {
                ShowMsg('请确认手机号', 'warning');
                return;
            };
            if(!this.captcha){
                ShowMsg('请输入验证码', 'warning');
                return;
            }

            $.post(`${this.$store.state.api.webUrl}/msg/sendyzm.json`, {
                type:'adduser',
                phone: this.mobile,
                captcha: this.captcha,
                featurecode:CONFIG.head.planname,
            }, data => {
                var tdata = JSON.parse(data);
                if(tdata.code=='v'){
                     //倒计时
                    this.loadingController = true;
                    this.btnDisabled = true
                    //倒计时
                    var remain = 60;
                    this.text = '请在' + remain + 's后重试'

                    var int = setInterval(() => {
                        if (remain != 0) {
                            remain--
                            this.text = '请在' + remain + 's后重试'
                        } else {
                            clearInterval(int);
                            this.text = '获取手机验证码';
                            this.btnDisabled = false;
                            this.loadingController = false;
                        }
                    }, 1000)

                }else{
                    showMsgBox(tdata.msg, 'error');
                }
               
            })
        }
    }
}
</script>

<style lang="scss" scoped>

</style>