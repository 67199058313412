<template>
  <footer class="footer">
    <div class="content">
      <!--
            <div class="content-text">
                <div class="top">
                    <div class="col map" style="width:25%">
                        <div class="title">网站地图</div>

                        <div
                        class="item"
                        v-for="(item, index) in $store.state.config.nav"
                        :key="index"
                        >
                            <a :href="item.href">
                                {{item.title}}
                            </a>
                        </div>
                    </div>

                    <div class="col map" style="width:25%">
                        <div class="title">系列产品</div>

                        <div
                        class="item"
                        v-for="(item, index) in $store.state.config.linkArr"
                        :key="index"
                        >
                            <a :href="item.href" target="_blank">
                                {{item.title}}
                            </a>
                        </div>
                    </div>


                    <div class="col info">
                        <div class="title">版权所有</div>

                        <div class="item">
                            客服电话：{{head.tel}}
                        </div>
                        <div class="item">
                            地址：{{head.address}}
                        </div>
                    </div>
                </div>
                
            </div>-->

      <div class="bottom">
        <div class="copy-right">
          <!-- <p>
                        <span class="item">
                            {{head.title}}
                        </span>
                    </p> -->
          <div class="copycenter">
            <p class="clear">
              <span>联系电话：{{head.tel}}</span>
              <span v-if="head.email">邮箱：{{head.email}}</span>
            </p>
            <p v-if="head.address">
              <span>地址：{{head.address}}</span>
            </p>
            <p>
              备案号：
              <a class="item"
                 href="https://beian.miit.gov.cn/#/Integrated/index"
                 target="_blank">
                {{head.icp}}
              </a>
              <a class="item"
                 href="https://beian.mps.gov.cn/#/query/webSearch?code=64010602001060\"
                 target="_blank">
                {{head.gwIcp}}
              </a>
            </p>
          </div>

        </div>
      </div>

    </div>
  </footer>
</template>
<script>
import CONFIG from '@config/config';

export default {

  props: {
    theme: {
      type: String,
      default: ''
    },
    fixed: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      head: CONFIG.head,
    }
  },
}
</script>
<style scoped lang="scss">
@import "@css/var.scss";

$textColor: #949494;

.footer {
  background: #343842;
  padding: 50px 0;
  color: white;

  .content-text {
    width: 1100px;
    display: inline-block;
  }

  a {
    color: #ffffff;
  }

  .top {
    overflow: hidden;
    padding: 0 30px 40px;

    .col {
      width: 50%;
      float: left;
      font-size: 14px;
      line-height: 2em;

      .title {
        font-size: 18px;
        margin-bottom: 16px;
      }

      .item {
        color: #ffffff;
      }
    }
  }

  .bottom {
    // text-align: center;
    // padding-top: 22px;
    .copy-right {
      line-height: 1em;
      color: #fff;
      p {
        line-height: 36px;
      }
      a {
        color: #fff;
      }

      .item {
        & + .item {
          margin-left: 1em;
        }
      }

      & + .copy-right {
        margin-top: 1em;
      }
    }
  }
}
// 居中
.copy-right {
  display: flex;
  justify-content: center;
}
.clear {
  display: flex;
  justify-content: space-between;
}
.copycenter{ width: 450px;}
</style>