<template>
  <div>
    <card class="content business">

      <span slot="title"
            class="titlespan">
        <i></i>交易信息
      </span>

      <el-link slot="title-r"
               :underline="false"
               type="primary"
               href="/business">
        更多>
      </el-link>
      <div class="businesssearch">
        <el-input placeholder="请输入公告名称"
                  v-model="searchText"
                  class="input-search">
          <el-button type="primary"
                     class="search-btn"
                     slot="append"
                     @click="searchHandler">
            搜索
          </el-button>
        </el-input>
      </div>
      <search-bar v-model="pgData"
                  @change="queryData"></search-bar>

      <list
            :data="tableData"
            :props="{title:'GGNAME', time:'GGFBTIME'}"
            :href="hrefBuilder"
            class="noticelist">
        <div slot-scope="scope">
          【{{scope.row.TYPENAME}}】
          【{{scope.row.CITYNAME}}】
          {{scope.row.GGNAME}}
        </div>
      </list>
    </card>
  </div>

</template>

<script>
import CONFIG from '@config/config';

import List from '@components/sys/list';
import Card from '@components/sys/card';

import SearchBar from '../../business/components/search-bar';

export default {
  components: {
    List,
    SearchBar,
    Card,
  },
  data () {
    return {
      pgData: {
        // type: 'zbgg',
        type: 'zbgg',
        leixing: '全部',
        citycode: '',
        featurecode: CONFIG.head.planname,
      },

      tableData: [],

      loadingController: false,

      searchText: '',
    };
  },
  methods: {
    queryData () {
      this.loadingController = true;

      this.$get(`${this.$store.state.api.webUrl}/index/information/getjyinfo.json`, {
        ...this.pgData,
        maxrow: 10,
      }, data => {
        this.tableData = data;

        this.loadingController = false;
      });
    },
    hrefBuilder (item) {
      var search = toSearch({
        ggcode: item.GGCODE,
        categoryid: item.GGTYPE
      });

      if (/SD/.test(item.GGCODE)) {
        return `/business/detail-sd${search}`
      }

      return `/business/detail${search}`;
    },
    // 搜索
    searchHandler () {
      this.goto({
        path: '/business',
        query: {
          searchcontent: this.searchText,
        },
      })
    },
  },
  mounted () {
    this.queryData();
  },
};
</script>

<style lang="scss" scoped>
.business {
  padding-top: 15px !important;

  .divider {
    margin: 10px 0 20px;
  }
}
.el-button--primary {
  color: #fff !important;
  background-color: #2252e7 !important;
  border-color: #2252e7 !important;
}
::v-deep .card {
  padding: 0;
  .head {
    height: 60px;
    background: #f9fafd;
    line-height: 60px !important;
    padding: 0;
    margin-bottom: 0 !important;
    display: flex;
    justify-content: space-between;
    .titlespan {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    span {
      color: #333333;
      font-size: 24px;
      font-weight: 400;
      i {
        display: inline-block;
        width: 4px;
        height: 23px;
        background: #247cd6;
        margin-right: 16px;
      }
    }
    .title-r {
      span {
        font-size: 14px;
        color: #999999;
      }
    }
  }
  // 样式
  .body .noticelist {
    .item {
      margin-top: 25px;
      line-height: 18px;
    }
    .item:last-child {
      margin-bottom: 50px;
    }
  }
}
/* 改变卡片的颜色 */
::v-deep .business {
  background: #f9fafd !important;
}
//搜索框样式 --复制
.businesssearch {
  width: 801px;
  height: 56px;
  border-radius: 3px;
  margin: 15px 0 25px 0;

  .input-search, .search-btn{
    height:56px;
  }

  ::v-deep .input-search .el-input__inner {
    height: 56px !important;
    padding-left: 15px;
    font-size: 18px;
    border: 1px solid #247cd6;
    // color: #999999;
  }
  ::v-deep .input-search input::placeholder {
    font-size: 18px;
    color: #999999;
  }
  ::v-deep .el-button {
    border-radius: 0px 3px 3px 0px;
    color: #ffffff;
    padding: 12px 30px;
    height: 56px;
    background: #247cd6;
    span {
      font-size: 18px;
      color: #ffffff;
    }
  }
}
</style>