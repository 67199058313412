<template>
    <div class="my-fixed-box">

        <div class="pop-item-inner" @click="openQuestionDialog">
            <img src="./image/qaw.png" class="my-img" alt="">
            常见问题
        </div>

        <Question ref="question"></Question>
    </div>

</template>

<script>
import Question from '../question';

export default {
    name: '',
    components: {
        Question,
    },

    data () {
        return {
        };
    },

    mounted () { },

    methods: {
        openQuestionDialog () {
            this.$refs.question.openDialog();
        },

    },
}
</script>

<style lang="scss" scoped>
.cjwt-top {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.cjwt-title {
    font-size: 18px;
    color: #333;
}
.cjwt-close {
    font-size: 32px;
    color: #333;
}

.my-fixed-box {
    position: fixed;
    right: 8px;
    bottom: 130px;
    border: 1px solid #409eff;
    z-index: 10;

    .pop-item {
        background-color: #409eff;
    }
    .pop-item-inner {
        width: 54px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background-color: #409eff;
        // background-color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        color: #fff;
        // border-radius: 5px;
    }

    .my-img {
        width: 24px;
        height: 24px;
    }
}
</style>
