<template>
  <div class="list">
    <a class="item"
       v-for="(item, index) in data"
       :key="index"
       :href="href(item)">
      <div class="addtime">
        {{item[props.time]}}
      </div>

      <div class="title">
        <slot :row="item">
          {{item[props.title]}}
        </slot>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    href: {
      type: Function,
      default: (row) => {
        return ``;
      },
    },
    props: {
      type: Object,
      default: () => ({
        title: 'title',
        time: 'time',
      }),
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@css/var.scss";

.list {
  .item {
    display: block;
    overflow: hidden;
    line-height: 1em;
    color: #666666;

    & + .item {
      margin-top: 28px;
    }

    // a悬浮 变色
    &:hover {
      color: $primaryColor;
    }

    .title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 8em;
    }

    .addtime {
      float: right;
    }
  }
}
</style>