<template>
    <ul class="tab-btn">
        <li 
        class="item"
        :class="{'active': item[props.value] === model}"
        v-for="(item, index) in data"
        :key="index"
        @click="selHandler(item)"
        >
            {{item[props.label]}}
        </li>
    </ul>
</template>

<script>
import MODEL_MIXIN from '@mixins/model';

export default {
    mixins: [MODEL_MIXIN],
    props: {
        data: {
            type: Array,
            default: () => [],
        },
        props: {
            type: Object,
            default: () => ({
                label: 'label',
                value: 'value',
            }),
        }
    },
    methods: {
        selHandler(item) {
            this.model = item[this.props.value];

            this.$emit('select');
        },
    },
}
</script>

<style lang="scss" scoped>
    @import '@css/var.scss';

    .tab-btn{
        overflow: hidden;

        .item{
            float: left;
            font-size: 16px;
            line-height: 1em;
            padding: 8px 10px;
            border-radius: 18px;
            border: 1px solid #d6d6d6;
            cursor: pointer;

            & + .item{
                margin-left: 1em;
            }

            &.active{
                color: white;
                background: $primaryColor;
                border-color: $primaryColor;
            }
        }
    }
</style>