<template>
    <div>
        <nuxt/>

        <fixedPopUp></fixedPopUp>
    </div>
</template>

<script>
import fixedPopUp from '@components/sys/fixed-pop-up';

export default {
    components: {
        fixedPopUp,
    },
    methods: {
        
    }
}
</script>

<style lang="scss" scoped>
#__layout{min-width: 1200px; min-height:100%;}
</style>