<template>
  <div class="search-bar">
    <div class="search-item">
      <div class="label">招标内容：</div>

      <div class="list">
        <div class="item"
             v-for="(item, index) in ggType"
             :class="[item.type===model.type ? 'active' : '']"
             @click="updateModel('type', item.type)"
             :key="index">
          {{item.name}}
        </div>
      </div>
    </div>

    <div class="search-item">
      <div class="label">项目类型：</div>

      <div class="list">
        <div class="item"
             v-for="(item, index) in proTypes"
             :class="[item.label===model.leixing ? 'active' : '']"
             @click="updateModel('leixing', item.label)"
             :key="index">
          {{item.label}}
        </div>
      </div>
    </div>

    <div class="search-item">
      <div class="label">项目区域：</div>

      <div class="list">
        <div class="item"
             v-for="(item, index) in cityList"
             :class="[item.cityid===model.citycode ? 'active' : '']"
             :key="index"
             @click="updateModel('citycode', item.cityid)">
          {{item.name}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CONFIG from '@config/config';

import MODEL_MIXIN from '@mixins/model';

export default {
  mixins: [MODEL_MIXIN],
  props: {
    value: {
      type: Object,
      default: () => ({
        // type: 'zbgg',
        // leixing: '招标公告',
        // citycode: CONFIG.head.citycode,
        type: 'all',
        leixing: '全部',
        citycode: CONFIG.head.citycode,
      }),
    },
  },
  data () {
    return {
      ggType: [
        // {
        //   name: '全部',
        //   type: 'all',
        //   id: '0',
        // },
        {
          name: '招标公告',
          type: 'zbgg',
          id: '1',
        },
        {
          name: '变更公告',
          type: 'bggg',
          id: '2',
        },
        {
          name: '补遗答疑',
          type: 'bydy',
          id: '3',
        },
        {
          name: '中标候选人公示',
          type: 'hxrgs',
          id: '4',
        },
        {
          name: '中标公告',
          type: 'zhongbiaogg',
          id: '5',
        },
        {
          name: '异常公告',
          type: 'ycgg',
          id: '6',
        },
      ],
      proTypes: [
        {
          value: '',
          label: '全部'
        },
        {
          value: 'jsgc',
          label: '建设工程'
        },
        {
          value: 'zfcg',
          label: '政府采购'
        },
        {
          value: 'qycg',
          label: '企业采购'
        },
      ],
      cityList: [],
    };
  },
  methods: {
    queryCity () {
      this.$get(`${this.$store.state.api.webUrl}/index/information/getcitylist.json`, {
        parentid: CONFIG.head.citycode,
      }, data => {
        this.cityList = data;
        this.cityList.unshift({
          name: '全部',
          cityid: '',
        });
      });
    },
    updateModel (key, value) {
      console.log(key, value)
      this.model[key] = value;
      this.$emit('change');
      // type zbgg leixing 建设工程  citycode 640100
    },
  },
  mounted () {
    this.queryCity();
  }
};
</script>

<style lang="scss" scoped>
@import "@css/var.scss";

.search-bar {
  cursor: default;
  .search-item {
    overflow: hidden;
    line-height: 1em;
    margin-top: 10px;
    .label {
      font-size: 16px;
      float: left;
      color: #333333;
      margin: 6px 0;
      margin-right: -80px;
      padding: 5px;
      // letter-spacing: 2px;
    }

    .list {
      margin-left: 88px;
      overflow: hidden;

      .item {
        font-size: 16px;
        float: left;
        color: #333333;
        margin: 6px 0;
        margin-right: 18px;
        padding: 5px 8px;
        cursor: pointer;
        transition: all 0.3s;

        &:not(.active):hover {
          background: $primaryColor1;
        }

        &.active {
          background: #2577e3;
          color: white;
        }
      }
    }
  }
}
</style>