<template>
    <div class="floatLayer">
        <a class="close-btn" href="javascript:;" @click.stop="closeView">×</a>

        <div class="tip-title">
            重要提醒
        </div>
        <div class="tip-context">
            <p>各位投标人请注意，一定要检查确认自己的西部ca锁型号是否为龙脉(一把蓝色的key)，目前平台只支持西部CA新版的加密锁，如若不是，请立即联系西部ca免费办理一把新锁（<span class="bg-yellow">注意有使用着旧版本西部CA锁的投标人才能免费领取新锁，如果没有办理过西部CA的锁，还是需要支付办理费用的</span>），新锁插上之后不能正常读取的，请先卸载旧版驱动，在国有资本交易平台 ”下载中心” 获取新版驱动（解密之后再安装，如有疑问拨打下方技术电话），安装好了之后再用新锁进行后续盖章及解密等操作。</p>
            <p>还有请一定注意，邮寄需要时间，务必提前办理。</p>
            <p class="ta-r">西部ca联系电话：4008-600271</p>
            <p class="ta-r">宁夏国有资本交易平台技术电话：15930190967</p>
        </div>

    </div>
</template>
<script>
    export default{
        data(){
            return{
                object: [],
                left:0,
                top:0,
                interval:50,
                distance:2,
                directionX:1,
                directionY:1,
                zIndex:100,
                target:"_blank",
                cursor:"pointer",
                i:0,

            }
        },
        props:['json'],
        mounted(){
            this.init(this.json);
        },
        methods:{
            closeView(){
                this.$emit("closeView");
            },
            init(json){
                var obj = document.querySelector(".floatLayer");
                //自定义
                obj.left=parseInt(json.left || this.left);
                obj.top = parseInt(json.top || this.top);
        obj.interval = json.interval || this.interval;
        obj.distance = json.distance || this.distance;
        obj.directionX = json.directionX || this.directionX;
        obj.directionY = json.directionY || this.directionY;
        obj.index = this.object.length;
        obj.url = json.url;
        obj.target = json.target || this.target;
        obj.t=null;
        //事件
        if (obj.url != undefined){
            obj.onclick = function () {
              switch (this.target) {
                  case "_blank":
                      open(this.url);
                      break;
                  case "_parent":
                      parent.location = this.url;
                  case "_top":
                      top.location = this.url;
                      break;
                  case "_self":
                      location = this.url;
                      break;
              }
          }
        }
        if (!json.noHoverPause) {
            obj.onmouseover = function () {
                clearInterval(this.t);
            }
            obj.onmouseout = function () {
                obj.t=setInterval(function(){that.float(obj.index)}, obj.interval);
            }
        }
        obj.src = json.src;
        obj.style.cursor = json.cursor || this.cursor;
        obj.style.position = "absolute";
        obj.style.left = obj.left + "px";
        obj.style.top = obj.top + "px";
        obj.style.zIndex = json.zIndex || this.zIndex;
        obj.style.width = json.width || "";
        obj.style.height = json.height || "";
        obj.style.directionX=json.directionX || this.directionX;
        obj.style.directionY=json.directionY || this.directionY;
        this.object.push(obj);
        //console.log(this.object)
        document.body.appendChild(obj);
        let that=this;
        console.log(obj.t)
        obj.t=setInterval(function(){that.float(obj.index)}, obj.interval);
      },
      float(index) {
        //console.log("dd",index);
            var pageWidth = document.documentElement.clientWidth;
            var pageHeight = document.documentElement.clientHeight;
            var scrollLeft = document.documentElement.scrollLeft;
            var scrollTop = document.documentElement.scrollTop;
 
            var obj = this.object[index];
            //console.log("s",obj)
 
            obj.left = obj.left + obj.directionX * obj.distance;
            if (obj.left <= scrollLeft) {
                obj.left = scrollLeft;
                obj.directionX = 1;
            }
            if (obj.left + obj.offsetWidth >= pageWidth + scrollLeft - 1) {
                obj.left = pageWidth + scrollLeft - 1 - obj.offsetWidth;
                obj.directionX = -1;
            }
 
            obj.top = obj.top + obj.directionY * obj.distance;
            if (obj.top <= scrollTop) {
                obj.top = scrollTop;
                obj.directionY = 1;
            }
            if (obj.top + obj.offsetHeight >= pageHeight + scrollTop - 1) {
                obj.top = pageHeight + scrollTop - 1 - obj.offsetHeight;
                obj.directionY = -1;
            }
 
            obj.style.left = obj.left + "px";
            obj.style.top = obj.top + "px";
        }
             
        }
    }
</script>
<style>
    .floatLayer{
        position:fixed;
        left:0;
        top:0;
        z-index:100;
        width: 500px;
        height: 300px;
        overflow: auto;
        background: #378CC5;
        padding: 20px;
        border:1px solid #efefef;
    }
    .close-btn{
        display:block;
        position:absolute;
        right:0px;
        top:0px;
        width:40px;
        height:40px;
        line-height:40px;
        text-align: center;
        overflow:hidden;
        font-size: 30px;
        color:#333;
        z-index:110;
    }
 
    .floatLayer img{
        position:relative;
        z-index:101;
    }

    .tip-title{
        text-align:center;
        font-size:22px;
    }

    .tip-context{
        text-indent:2em;
        line-height:30px;
        font-size:16px;
        margin-top:20px;
    }

    .ta-r{
        text-align:right;
    }

    .bg-yellow{
        background:yellow;
    }
</style>