<template>
  <div class="friendlink">
    <div class="content">
      <span class="friendtext">友情链接：</span>
      <div class="address">
        <a v-for="(item,index) in urlList"
           :key="index"
           :href="item.address">{{item.text}}</a>
      </div>

    </div>
  </div>
</template>

<script>
import CONFIG from '@config/config';
export default {
  data () {
    return {
      urlList: CONFIG.urlList
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@css/var.scss";
.friendlink {
  height: 66px;
  // background: #343842;
  // opacity: 0.05;
  background: rgb(#343842, 0.05);

  .content {
    height: 100%;
    line-height: 66px;
  }
  .friendtext {
    float: left;
    font-size: 16px;
    color: #666666;
    margin-right: 22px;
  }
  .address {
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
      display: inline-block;
      font-size: 16px;
      color: #666666;
    }
    a:hover {
      color: $primaryColor;
    }
  }
}
</style>