<template>
    <div class="guide content">
        <div class="bread">
            <bread :data="[{path: '/guide', title: '帮助中心'}]"></bread>
        </div>

        <card class="main-con">
            <span slot="title">
                常见问题
            </span>

            <!-- 帮助中心 -->
            <div class="help-con" v-loading="loadingController">
                <div class="search-bar">
                    <el-input 
                    placeholder="请输入关键词" 
                    v-model="pgData.title" 
                    class="search-input"
                    >
                        <el-button 
                        slot="append" 
                        icon="el-icon-search"
                        @click="searchHandler"
                        >
                            搜索
                        </el-button>
                    </el-input>
                </div>

                <div class="qa-con">
                    <div class="type-select">
                        <ul>
                            <li 
                            v-for="(item, index) in typeList"
                            :key="index"
                            :class="{active: item.typecode==pgData.typecode}" 
                            @click="typeClick(item.typecode)">
                                {{item.typename}}
                            </li>
                        </ul>
                    </div>

                    <ul class="qa-list">
                        <li 
                        v-for="(item, index) in tableData"
                        :key="index"
                        >
                            <QuwationItem :data="item" :key="item.guid +index"></QuwationItem>
                        </li>
                    </ul>
                </div>

                <!-- 分页 -->
                <my-pagination
                    ref="page"
                    class="page"
                    :action="`${$store.state.api.webUrl}/index/information/getcjwt.json`"
                    v-model="tableData"
                    :search="pgData"
                    :loading.sync="loadingController"
                ></my-pagination>
            </div>
        </card>
    </div>
</template>

<script>
import CONFIG from '@config/config';

import Card from '@components/sys/card';
import List from '@components/sys/list';
import Bread from '@components/sys/bread';
import QuwationItem from '@/components/sys/question/list-item.vue';

export default {
    components: {
        Card,
        List,
        Bread,
        QuwationItem,
    },
    data: () => ({
        num:'0',
        typeList:[],
        pgData: {
            typecode: '',
            sortorder: 'desc',//排序规则
            sortname: 'addtime',// 排序字段 modifydate
            pageindex: 1,
            pagesize: 8,
            title:'',
            featurecode:CONFIG.head.planname,
        },

        tableData:[],//问题列表

        loadingController: false,
    }),
    methods: {
        tabClick: function(val){
            this.num = val;
        },
        typeClick: function(val){
            this.pgData.typecode = val;
            
            this.searchHandler();
        },
        // 搜索
        searchHandler: function(){
            this.$refs.page.queryData();
        },
    },
    mounted() {
        var that = this;

        // 获取帮助中心接口
        that.$get(`${this.$store.state.api.webUrl}/index/information/getcjwttype.json`,{
            featurecode:CONFIG.head.planname,
        }, function (data) {
            that.typeList = data;
            that.pgData.typecode = data[0].typecode;
            this.$refs.page.queryData();
        });
    },
}
</script>

<style lang="scss" scoped>
    @import '@css/var.scss';

    .guide{
        margin-bottom: 25px;

        .main-con{
            min-height: 400px;
        }
    }

    .help-con{ margin: 0 auto; 
        .qa-con{ margin: 20px 0; 
            .type-select ul{ 
                overflow: hidden; border-bottom: 1px solid #f0f0f0; text-align: center; 
                
                li{display: inline-block; font-size: 16px; padding: 0 30px; height: 40px; line-height: 40px; cursor: pointer;
                    &.active{ border-bottom: 2px solid $primaryColor;}
                }
            }
            .qa-list{
                min-height: 500px;
                padding-top: 20px;
                li{
                    // margin-top: 20px; 

                    h4{
                        font-size: 16px;
                    }
                    
                    
                }
                
            }
          
        }
    }

    .search-bar{
        width: 550px;
        margin: 0 auto;
    }

    .search-input {
        ::v-deep {
            .el-input__inner{
                border-color: $primaryColor;
                border-radius: 0;
            }

            .el-input-group__append{
                border-radius: 0;
                border-color: $primaryColor;
                background-color: $primaryColor;
                color: white;
            }
        }
    }
</style>

<style>
    .qa-list li p{ margin: 1em 0;  color: #333; line-height: 30px;}
    .qa-list li p span{ font-size: 14px !important; }
</style>