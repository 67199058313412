<template>
  <div class="login">
    <el-row>
      <el-col :span="13">
        <div class="leftbox">
          <img class="logoimg"
               src="/img/hzzlogin/newlogo.png"
               alt="惠泽招电子招投标交易平台">
        </div>
      </el-col>
      <el-col :span="11">
        <div class="rightbox">
          <div class="back">
            <div class="backcon"
                 @click="backhome">
              <span class="backimg"></span>
            </div>
          </div>
          <div class="choose">
            <p class="identity">请选择您的身份登录</p>
            <div class="person">
              <a v-for="(item,index) in personlist"
                 :key="index"
                 class="personcon"
                 :href="item.href">
                <img :src="item.imgsrc"
                     :alt="item.con">
                <p>{{item.con}}</p>
              </a>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import CONFIG from '@config/config';
export default {
  layout: 'empty',
  data () {
    return {
      personlist: CONFIG.personlist
    }
  },
  methods: {
    backhome () {
      console.log("点击")
      this.$router.push(
        {
          path: '/'
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  width: 100%;
  height: 100%;
  position: fixed;
  ::v-deep .el-row,
  ::v-deep .el-col-13,
  ::v-deep .el-col-11 {
    height: 100%;
  }
  .leftbox {
    width: 100%;
    height: 100%;
    background: url("/img/hzzlogin/newright_bg.jpg") no-repeat;
    background-size: cover;
    .logoimg {
      padding-top: 46px;
      padding-left: 66px;
    }
  }
  // 右侧
  .rightbox {
    width: 100%;
    height: 100%;
    position: relative;
    .back {
      float: right;
      margin-top: 22px;
      .backcon {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        cursor: pointer;
        margin-right: 29px;
      }
      .backimg {
        display: inline-block;
        width: 18px;
        height: 16px;
        background: url("/img/hzzlogin/back.png");
        margin-right: 7px;
      }
      .backhome {
        font-size: 14px;
        color: #666666;
      }
    }
    .choose {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-top: -41px;
      .identity {
        text-align: center;
        font-size: 18px;
        color: #333333;
      }
      .person {
        width: 468px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .personcon {
          display: inline-block;
          width: 201px;
          height: 201px;
          background: #ffffff;
          border: 1px solid #e3e3e3;
          border-radius: 5px;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          margin-top: 65px;
          p {
            font-size: 18px;
            color: #333333;
            margin-top: 13px;
          }
        }
      }
    }
  }
}
</style>