<template>
    <el-breadcrumb class="bread" separator="/">
        <el-breadcrumb-item 
        v-for="(item, index) in innerData"
        :key="index"
        :to="item.path"
        >
            {{item.title}}
        </el-breadcrumb-item>
    </el-breadcrumb>
</template>

<script>
export default {
    props: {
        data: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        innerData() {
            return [
                {
                    title: '首页',
                    path: '/',
                },
                ...this.data,
            ];
        },
    },
};
</script>

<style lang="scss" scoped>
    .bread{
        margin: 18px 0;
    }
</style>