<template>
    <table 
    v-if="categoryid==='hxrgs' && !data.ext3" 
    class="table-hxrgs"
    >
        <tr v-if="data.Data_model.fjdwjly">
            <td class="reason">否决投标单位及理由</td>
            <td>
                <div 
                    v-html="data.Data_model.fjdwjly"
                ></div>
            </td>
        </tr>
        <tr v-if="data.Data_model.ext_complaints">
            <td>提出异议渠道和方式</td>
            <td>
                <div 
                    v-html="data.Data_model.ext_complaints"
                ></div>
            </td>
        </tr>
        <tr v-if="data.Data_model.qbtbdw">
            <td>全部投标单位</td>
            <td>
                <div 
                    v-html="data.Data_model.qbtbdw"
                ></div>
            </td>
        </tr>
    </table>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default: () => ({
                Data_model: {},
            }),
        },
    },
    computed: {
        categoryid() {
            return this.getQuery('categoryid');
        },
    },
};
</script>

<style lang="scss" scoped>
    $tableBorder: 1px #dfdfdf solid;

    table {
        width: 100%;
        border-top: $tableBorder;
        border-left: $tableBorder;
        margin-bottom: 1em;
        margin: 1em 0;

        td{
            border-right: $tableBorder;
            border-bottom: $tableBorder;
            padding: 1em;
        }
    }

    .reason{
        width: 12em;
    }
</style>