<template>
  <div class="news content">
    <div class="bread">
      <bread :data="[{path: '/business', title: '交易信息'}]"></bread>
    </div>

    <card class="search">
      <div class="title">
        <div class="text">
          交易信息
        </div>

        <div class="search-con">
          <el-input v-model="pgData.searchcontent"
                    size="middle">
            <el-button slot="append"
                       @click="queryData">搜索</el-button>
          </el-input>
        </div>
      </div>

      <search-bar v-model="pgData"
                  @change="queryData"></search-bar>
    </card>

    <card class="list"
          v-loading="loadingController">
      <list :data="tableData"
            :props="{title:'ggname', time:'ggfbtime'}"
            :href="hrefBuilder">
        <template slot-scope="scope">
          <div class="main">
            【{{scope.row.typename}}】
            {{scope.row.ggname}}
          </div>

          <div class="sub">
            <span class="sub-item">
              地区：{{scope.row.cityname}}
            </span>
            <span class="sub-item">
              项目类型：{{scope.row.leixing}}
            </span>
            <span v-if="!~['zhongbiaogg', 'ycgg'].indexOf(scope.row.ggtype)"
                  class="sub-item">
              截止时间：{{scope.row.ggendtime}}
            </span>
          </div>
        </template>
      </list>

      <my-pagination ref="page"
                     class="page"
                     :action="`${$store.state.api.webUrl}/index/information/getjyinfopg.json`"
                     v-model="tableData"
                     :search="pgData"
                     :loading.sync="loadingController"></my-pagination>
    </card>
  </div>
</template>

<script>
import CONFIG from '@config/config';

import Card from '@components/sys/card';
import List from '@components/sys/list';
import Bread from '@components/sys/bread';

import SearchBar from './components/search-bar';

export default {
  components: {
    Card,
    List,
    Bread,
    SearchBar,
  },
  watch: {
    '$route.query.searchcontent': {
      handler (val) {
        this.updateSearch();
      }
    }
  },
  data () {
    return {
      tableData: [],

      pgData: {
        searchtype: 'ggname',
        searchcontent: this.$route.query.searchcontent || '',
        type: 'all',
        leixing: '全部',
        citycode: '',
        featurecode: CONFIG.head.planname,
      },

      types: [],

      loadingController: false,
    };
  },
  methods: {
    hrefBuilder (item) {
      var search = toSearch({
        ggcode: item.ggcode,
        categoryid: item.ggtype,
        featurecode: CONFIG.head.planname
      });

      if (/SD/.test(item.ggcode)) {
        return `/business/detail-sd${search}`
      }

      return `/business/detail${search}`;
    },
    queryData () {
      this.$refs.page.queryData();
    },
    updateSearch () {
      this.pgData.searchcontent = this.$route.query.searchcontent;

      this.queryData();
    }
  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>
@import "@css/var.scss";

.news {
  margin-bottom: 26px;

  .search {
    .title {
      color: $primaryColor;
      font-size: 18px;
      font-weight: bold;
      border-bottom: solid 1px #d6d6d6;
      overflow: hidden;
      padding-bottom: 25px;
      margin-bottom: 25px;

      .text {
        margin-top: 8px;
        float: left;
      }

      .search-con {
        width: 570px;
        float: right;

        ::v-deep {
          .el-input__inner {
            border-color: $primaryColor;
            border-radius: 0;
          }

          .el-input-group__append {
            border-radius: 0;
            border-color: $primaryColor;
            background-color: $primaryColor;
            color: white;
          }
        }
      }
    }
  }

  .list {
    min-height: 400px;

    ::v-deep {
      .item + .item {
        border-top: solid 1px #d6d6d6;
        padding-top: 24px;
      }
    }

    .main {
    }

    .sub {
      color: #7d7d7d;
      margin-top: 24px;

      .sub-item {
        & + .sub-item {
          margin-left: 100px;
        }
      }
    }
  }

  .page {
    margin-top: 1em;
  }
}
</style>
