// list item

<template>
    <div class="question-item">

        <div class="title">{{data.title}}</div>

        <div class="answer" v-html="data.answer"></div>

        <!-- 视频列表 -->
        <div class="video-box" v-if="!!videoList && videoList.length > 0">

            <div class="video-item" v-for="(item,i) in videoList" :key="'v' +i+ parseInt(Math.random()*1000)">
                <video class="video-item" ref="video" :src="item.url" controls :key="item.url"></video>
            </div>

        </div>

        <!-- 图片列表 -->

        <div class="img-box" v-if="!!imgList && imgList.length> 0">

            <div class="img-item" v-for="(item,i) in imgList" :key="'i' +i + parseInt(Math.random()*1000)">
                <el-image style="width: 100px; height: 100px" fit="cover" :src="item.url"
                    :preview-src-list="previewImgList">' +
                </el-image>
            </div>

        </div>

    </div>
</template>

<script>
export default {
    name: '',
    props: {
        data: {
            type: Object,
            default: () => { return {}; }
        },
        guid: {
            type: String,
            default: '',
        },
    },
    data () {
        return {
            videoList: [],
            imgList: [],
            previewImgList: [],
        };
    },
    mounted () {
        this.getVideo();
        this.getImg();
    },
    methods: {
        getVideo: function () {
            if (!this.data.videoguid) {
                this.videoList = [];
                return;
            }

            this.$get('/standalonfile/operate/getlist.json', { fileguid: this.data.videoguid }, function (data) {
                if (!data) {
                    this.videoList = [];
                    return;
                }

                this.videoList = data.map((val) => {
                    val.name = val.filename;
                    val.url = val.realip + val.filesavepath.replace(/\\/g, '/');

                    return val;
                });

            });
        },

        getImg: function () {
            if (!this.data.imgguid) {
                this.imgList = [];
                this.previewImgList = [];
                return;
            }

            this.$get('/standalonfile/operate/getlist.json', { fileguid: this.data.imgguid }, function (data) {
                if (!data) {
                    this.imgList = [];
                    this.previewImgList = [];
                    return;
                }

                this.imgList = data.map((val) => {
                    val.name = val.filename;
                    val.url = val.realip + val.filesavepath.replace(/\\/g, '/');

                    this.previewImgList.push(val.url);

                    return val;
                });

            });
        },

    },
}
</script>

<style scoped>
.question-item {
    padding: 20px 0;
}
.title {
    font-size: 16px;
    font-weight: bold;
    color: #000;
    text-align: left;
}
.answer {
    margin-top: 20px;
    font-size: 14px;
}

.video-box {
    /* border: 1px solid; */
    margin-top: 20px;
    display: flex;
}

.video-item {
    width: 280px;
    height: 157px;
    margin: 0 20px 20px 0;
    /* display: inline-block; */
}

.img-box {
    display: flex;
    /* border: 1px solid; */
    margin-top: 20px;
}
.img-item {
    width: 100px;
    height: 100px;
    margin: 0 10px 10px 0;
}
</style>
