<template>
    <img
        class="captcha"
        :src="src" 
        @click="clickHandler"
        :style="{height: computeSize}"
    />
</template>

<script>
export default {
    props: {
        size: {
            type: [String, Number],
            default: 'auto',
        },
    },
    data() {
        return {
            src: `${this.$store.state.api.webUrl}/reg/captcha.htm`,
        };
    },
    computed: {
        computeSize() {
            if (typeof this.size === 'number') {
                return `${this.size}px`;
            }

            return this.size;
        },
    },
    methods: {
        clickHandler() {
            var random = Math.random();
            this.src = `${this.$store.state.api.webUrl}/reg/${random}/captcha.htm`;
        }
    }
};
</script>

<style lang="scss" scoped>
    .captcha{
        cursor: pointer;
        display: block;
        height: 30px;
    }
</style>