<template>
    <div class="layout-default">
        <my-header></my-header>

        <nuxt />

        <el-backtop 
            class="backtop-layout"
            target="#__nuxt"
            v-if="showBacktop"
        ></el-backtop>

        <my-footer></my-footer>

        <fixedPopUp></fixedPopUp>
    </div>
</template>

<script>
import CONFIG from '@config/index';
import MyHeader from '@components/sys/my-header/index';
import MyFooter from '@components/sys/my-footer';
import appNode from '@js/app-node';
import fixedPopUp from '@components/sys/fixed-pop-up';

export default {
    components: {
        MyHeader,
		MyFooter,
        fixedPopUp,
    },
    data() {
        return {
			showBacktop:true
        };
    },
    methods: {
        dealCenterClick(){
            window.open(`/deal-nav`)
        },
        onprint(event){
            if(event.type === 'afterprint'){
                this.showBacktop = true
            }else{
                this.showBacktop = false
            }
        }
    },
    
    mounted() {
        window.addEventListener('beforeprint', this.onprint ); 
        window.addEventListener('afterprint', this.onprint); 
    }
};
</script>

<style scoped lang="scss">
#__layout{min-width: 1200px; min-height:100%;}

.layout-default {
    width: 100%;
}

.backtop-layout{
    width: 60px;
    height: 60px;
}
</style>

<style type="text/css" media=print>
    @media print {
        @page {
            size: auto;
        }

        body{
            /* 打印预览时缩放比例默认50% */
            zoom: 50%;
        }
        body, html{
            height: auto!important;
            /* 打印预览时强制勾选打印的背景图片 */
            -webkit-print-color-adjust: exact; 
        }
    }
</style>
