<template>
    <div class="cahandle content">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>请输入办理/续费CA的相关信息</span>
            </div>
            <div class="text item">
                <el-row :gutter="20">
                    <el-col :span="6" class="label">
                        <i class="el-icon-office-building"></i>
                        <span class="pr-5">单位名称</span>
                    </el-col>
                    <el-col :span="18">
                        <el-input v-model="form.str1" placeholder="请输入单位名称"></el-input>
                    </el-col>
                </el-row>

                <el-row :gutter="20">
                    <el-col :span="6" class="label">
                        <i class="el-icon-postcard"></i>
                        <span class="pr-5">办理/续费证书数量</span>
                    </el-col>
                    <el-col :span="18">
                        <el-input 
                            onkeyup="value=value.replace(/[^\d]/g,'')"
                            v-model="form.str2" 
                            placeholder="请输入办理证书数量">
                        </el-input>
                    </el-col>
                </el-row>

                <el-row :gutter="20">
                    <el-col :span="6" class="label">
                        <i class="el-icon-mobile"></i>
                        <span class="pr-5">联系方式</span>
                    </el-col>
                    <el-col :span="18">
                        <el-input 
                            onkeyup="value=value.replace(/[^\d\-\d]/g,'')" 
                            v-model="form.str3" 
                            placeholder="请输入联系方式">
                        </el-input>
                    </el-col>
                </el-row>
                
                <el-row class="btn-row">
                    <el-button @click="submit" type="primary">下一步</el-button>
                </el-row>
            </div>
        </el-card>
    </div>
</template>

<script>


export default {
    components: {
        
    },
    data: () => ({
        form:{
            type:'nxhzzcaapply'
        },
    }),
    methods: {
        submit:function(){
            var that = this;
            if(!this.form.str1){
                showMsg('请输入单位名称','error');
                return;
            }else if(!this.form.str2){
                showMsg('请输入办理证书数量','error');
                return;
            }else if(!this.form.str3){
                showMsg('请输入联系方式','error');
                return;
            }else{
                that.$post(`${this.$store.state.api.webUrl}/index/information/gatherinfo.json`, that.form, data => {
                    showMsg('信息录入成功','success')
                    window.location.href = 'https://online.cwca.com.cn/'
                });
            }
        }
    },
    mounted() {
        
    },
}
</script>

<style lang="scss">
@import '@css/var.scss';
.cahandle{
    min-height: 600px;

    .box-card{
        width: 1000px;
        margin: 120px auto;
        .el-row{
            margin-top: 40px
        }
        .clearfix{
            text-align: center;

            span{ font-size: 20px; }
        }
        .text{
            text-align: right;
            line-height: 36px;
            .label{ font-size: 18px; color: rgb(24, 144, 255); }
            .label::after{ content:"*";color:#f56c6c;margin-right:4px}
            .el-input-number{ 
                width: 100%; 
                .el-input__inner{ 
                    text-align: left !important; 
                }
            }
            
        }
    }
}

.pr-5{
    padding-right: 5px;
}

.btn-row{
    text-align:center;
    margin-top: 60px;
}
</style>


