<template>
    <div class="register">
        <div class="center">
            <div class="logo">
                {{title}}
            </div>

            <card class="form-card">
                <my-form 
                class="form"
                v-model="form" 
                label-width="180px"
                @submit="submitHandler"
                >
                    <el-form-item label="用户类型" prop="regtype" required>
                        <el-radio-group v-model="form.regtype">
                            <el-radio label="0" v-model="form.regtype">单位</el-radio>
                            <!-- <el-radio label="1" v-model="form.regtype">自然人</el-radio> -->
                        </el-radio-group>
                    </el-form-item>

                    <template v-if="form.regtype === '0'">
                        <el-form-item 
                        key= "Zyrycode" 
                        class="Zyrycode"
                        prop="Zyrycode" 
                        :rules="[{ validator: zyrycodeValidate, trigger: ['blur', 'change'] }]" 
                        required 
                        >
                            <template slot="label">
                                <el-select 
                                class="zyrycode-label-sel" 
                                v-model="form.Zjtype" 
                                @change="zjtypeChange"
                                placeholder="请选择代码类型"
                                >
                                    <el-option
                                        v-for="item in options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                    ></el-option>
                                </el-select>
                            </template>

                            <el-input v-model.trim="form.Zyrycode"></el-input>
                        </el-form-item>

                        <!-- <el-form-item 
                        label="统一社会信用代码" 
                        key="Zyrycode" 
                        class="Zyrycode"
                        prop="Zyrycode" 
                        :rules="[...newRule('统一社会信用代码', 'required'),{ validator: zyrycodeValidate, trigger: ['blur', 'change'] }]" 
                        >
                            <el-input v-model.trim="form.Zyrycode"></el-input>
                        </el-form-item> -->

                        <el-form-item 
                        label="单位名称" 
                        prop="Zjtypename" 
                        :rules="[...newRule('单位名称', 'required'), { validator: commonValidate, trigger: 'blur' },]" 
                        >
                            <el-input v-model.trim="form.Zjtypename"></el-input>
                        </el-form-item>

                        <el-form-item label="注册角色" prop="Detail" :rules="newRule('注册角色', 'required')">
                            <my-select 
                                v-model.trim="form.Detail"
                                :data="detailList"
                            ></my-select>
                        </el-form-item>
                    </template>

                    <el-form-item label="注册人姓名" prop="truename" :rules="newRule('注册人姓名', 'required')">
                        <el-input v-model.trim="form.truename"></el-input>
                    </el-form-item>
                    
                    <el-form-item v-if="form.regtype=='1'" label="身份证号" prop="by1" :rules="newRule('身份证号', 'required', 'min18', 'max18')">
                        <el-input v-model.trim="form.by1"></el-input>
                    </el-form-item>

                    <el-form-item 
                    label="登录名" 
                    prop="Extloginname" 
                    :rules="[...newRule('登录名', 'required', 'min5', 'max20'), { validator: extloginnameValid, trigger: 'blur'},]"
                    >
                        <el-input v-model.trim="form.Extloginname" v-angle="form.Extloginname"></el-input>
                    </el-form-item>

                    <el-form-item 
                    label="登录密码" 
                    prop="extpwd" 
                    :rules="[...newRule('登录密码', 'required', 'min6', 'max20'), { validator: extpwdValid, trigger: 'blur' },]"
                    >
                        <el-input 
                            type="password" 
                            v-model.trim="form.extpwd" 
                            placeholder="输入不少于6位数字和字母组合"
                        ></el-input>
                    </el-form-item>

                    <el-form-item 
                    label="确认密码" 
                    prop="qrpwd"
                    :rules="[...newRule('确认密码', 'required', 'min6', 'max20'), { validator: qrpwdValid, trigger: 'blur' },]"
                    >
                        <el-input type="password" v-model.trim="form.qrpwd"></el-input>
                    </el-form-item>

                    <el-form-item 
                    label="邮箱" 
                    prop="ExtEmail" 
                    :rules="[...newRule('邮箱', 'required', 'email')]"
                    >
                        <el-input v-model.trim="form.ExtEmail"></el-input>
                    </el-form-item>

                    <el-form-item label="验证码" prop="captcha" :rules="newRule('验证码', 'required')">
                        <el-input 
                        v-model.trim="form.captcha" 
                        >
                            <captcha slot="append" size="30px"></captcha>
                        </el-input>
                    </el-form-item>

                    <el-form-item 
                    label="手机号" 
                    prop="Extmobile" 
                    :rules="[...newRule('手机号', 'required', 'mobile'), { validator: commonValidate, trigger: 'blur' },]"
                    >
                        <el-input v-model.trim="form.Extmobile"></el-input>
                    </el-form-item>

                    <el-form-item 
                    label="手机验证码" 
                    prop="sjyzm"
                    :rules="newRule('手机验证码', 'required')"
                    >
                        <input-yzm
                            :mobile="form.Extmobile"
                            :captcha="form.captcha"
                            v-model.trim="form.sjyzm"
                        ></input-yzm>
                    </el-form-item>

                    <tos v-model="tosCheck"></tos>

                    <template slot="btn" slot-scope="scope">
                        <el-button type="primary" @click="scope.submitHandler">注册</el-button>
                    </template>
                </my-form>
            </card>
        </div>
    </div>
</template>

<script>
import CONFIG from '@config/config';

import Card from '@components/sys/card';
import Captcha from './components/captcha';
import InputYzm from './components/input-yzm';
import Tos from './components/tos';

export default {
    layout: 'empty',
    components: {
        Card,
        Captcha,
        InputYzm,
        Tos,
    },
    data() {
        return {
            title: CONFIG.head.title,
            tosCheck:false,
            form: {
                regtype: '0',

                Zjtype: '97',
                Zyrycode: '',

                Zjtypename: '',
                Detail: '',
                truename: '',
                by1: '',

                Extloginname: '',
                extpwd: '',
                qrpwd: '',

                ExtEmail: '',
                captcha: '',
                Extmobile: '',
                sjyzm: '',
                featurecode:CONFIG.head.planname,
            },
            //代码类型列表
            options:[
                {
                    value: '97',
                    label: '统一社会信用代码'
                }, 
                // {
                //     value: '98',
                //     label: '组织机构代码'
                // }, 
                {
                    value: '96',
                    label: '其他一证一照代码'
                },
            ],
            //注册角色列表
            detailList: [
                {
                    value: '投标人',
                    key: '投标人'
                }, {
                    value: '招标代理',
                    key: '招标代理'
                },
                {
                    value: '采购人',
                    key: '招标人'
                },
                //{
                //     value: '出让人',
                //     key: '出让人'
                // }, {
                //     value: '竞买人',
                //     key: '竞买人'
                // }, {
                //     value: '拍卖机构',
                //     key: '拍卖机构'
                // },
            ]
        };
    },
    methods: {
        zjtypeChange() {
            this.form.Zyrycode = '';
        },
        zyrycodeValidate(rule, value, callback) {
            if (!value) {
                return callback(new Error('必填'));
            }

            if(this.form.Zjtype === '98'){
                var regCode = /^[a-zA-Z0-9]{8}-[a-zA-Z0-9]{1}$/;

                if (!regCode.test(this.form.Zyrycode)){
                    callback(new Error('请按正确格式填写组织机构代码! 正确格式 XXXXXXXX-X'));
                }else{
                    this.commonValidate(rule, value, callback);
                }
            }else if(this.form.Zjtype == '97'){
                var patrn = /^[0-9A-Z]+$/,
                    code = this.form.Zyrycode
                //18位校验及大写校验
                if (code.length !== 18 || !patrn.test(code) ) {
                    callback(new Error("不是有效的十八位统一社会信用代码！"));
                }
                else {
                    var Ancode,
                        Ancodevalue,
                        total = 0,
                        weightedfactors = [1, 3, 9, 27, 19, 26, 16, 17, 20, 29, 25, 13, 8, 24, 10, 30, 28],
                        str = '0123456789ABCDEFGHJKLMNPQRTUWXY';

                    for (var i = 0; i < code.length - 1; i++) {
                        Ancode = code.substring(i, i + 1);
                        Ancodevalue = str.indexOf(Ancode);
                        total = total + Ancodevalue * weightedfactors[i];
                    }

                    var logiccheckcode = 31 - total % 31;

                    if (logiccheckcode == 31) {
                        logiccheckcode = 0;
                    }

                    var Str = "0,1,2,3,4,5,6,7,8,9,A,B,C,D,E,F,G,H,J,K,L,M,N,P,Q,R,T,U,W,X,Y";
                    var Array_Str = Str.split(',');

                    logiccheckcode = Array_Str[logiccheckcode];

                    var checkcode = code.substring(17, 18);

                    if (logiccheckcode !== checkcode) {
                        callback(new Error("不是有效的统一社会信用代码！"));
                    }else{
                        this.commonValidate(rule, value, callback);
                    }
                }
            } else if (this.form.Zjtype === '96') {
    
                this.commonValidate(rule, value, callback);
             
                // callback()
            }
        },
        commonValidate(rule, value, callback) {
            $.post(`${this.$store.state.api.webUrl}/reg/${rule.field}/checkdata.json`, {
                type: rule.field,
                txt: value,
                featurecode:this.form.featurecode
            }, data => {
                return this.validateAjaxResCheck(data, callback,rule.field);
            });
        },
        validateAjaxResCheck(data, cb,field) {
            var res = JSON.parse(data);
            
            if (res.code === 'v') {
                // 判断是否是单位名称  如果是赋值给登录名字段
                // if(field=='Zjtypename'){
                //     this.form.Extloginname = this.form.Zjtypename;
                // }
                return cb();
            } else {
                if(field == 'Zyrycode'){
                    if(this.form.Zjtype === '96'){
                        return cb(new Error('该其他一证一照代码已注册使用过，请联系贵公司该注册人，或可发申请（加盖公章）到惠泽招邮箱进行‘登录名找回，密码重置’'));
                    }else if(this.form.Zjtype === '97'){
                        return cb(new Error('该统一社会信用代码已注册使用过，请联系贵公司该注册人，或可发申请（加盖公章）到惠泽招邮箱进行‘登录名找回，密码重置’'));
                    }
                }else{
                    return cb(new Error(res.msg));
                }
               
            }
        },
        extloginnameValid(rule, value, callback) {
            var flag = /[`~!@%#$^&*()=|{}':;',\[\].<>《》\/\?~！@#￥……&*（）——|{}【】‘；：”“'。，、？"\\]/;
                
            // 判断 even 是否包含特殊字符
            if(flag.test(value)){
                return callback(new Error('不能包含符号字符'));
            }

            return this.commonValidate(rule, value, callback);
        },
        extpwdValid(rule, value, callback) {
            var reg = /^(?=.*[a-zA-Z]+)(?=.*[0-9]+)[a-zA-Z0-9]+$/;

            if (!reg.test(value)) {
                return callback(new Error('请使用数字字母组合'));
            }

            callback();
        },
        qrpwdValid(rule, value, callback) {
            if (!this.form.extpwd) {
                return callback(new Error('请先输入登录密码'));
            }

            if (value !== this.form.extpwd) {
                return callback(new Error('两次输入密码不一致'));
            }

            callback();
        },
        submitHandler() {
            if (!this.tosCheck) {
                showMsg('请阅读并勾选同意“我已接受并同意网站《用户服务条款》”');
                return;
            }
            if(this.getQuery('plan')){
                this.form.featurecode = this.getQuery('plan')
            }
            $.post(`${this.$store.state.api.webUrl}/reg/save.json`, this.form, (data, res) => {
                var tdata = JSON.parse(data);
                if(tdata.code=='v'){
                    showMsgBox(tdata.msg, 'success', () => {
                        this.goto('/');
                    });
                }else{
                    showMsgBox(tdata.msg, 'error');
                }
                
            });
        },
    },
    directives: {
        angle: {
            inserted(el, binding, vnode) {
                el.children[0].value = binding.value;
                let angleValue = el.children[0];

                el.addEventListener('keyup', function (e) {
                    e = e || window.event;
                    let str = angleValue.value;
                    let tmp = '';

                    var arr = [
                        [/：/g, ':'],
                        [/。/g, '.'],
                        [/“/g, '"'],
                        [/”/g, '"'],
                        [/【/g, '['],
                        [/】/g, ']'],
                        [/《/g, '<'],
                        [/》/g, '>'],
                        [/，/g, ','],
                        [/？/g, '?'],
                        [/、/g, ','],
                        [/；/g, ';'],
                        [/（/g, '('],
                        [/）/g, ')'],
                        [/‘/g, "'"],
                        [/’/g, "'"],
                        [/『/g, '['],
                        [/』/g, ']'],
                        [/「/g, '['],
                        [/」/g, ']'],
                        [/﹃/g, '['],
                        [/﹄/g, ']'],
                        [/〔/g, '{'],
                        [/〕/g, '}'],
                        [/—/g, '-'],
                        [/·/g, '.'],
                    ];

                    arr.forEach(item => {
                        str = str.replace(item[0], item[1]);
                    });

                    /* 正则转换全角为半角 */
                    for (var i = 0; i < str.length; i++) {
                        if (str.charCodeAt(i) === 12288) {
                            tmp += String.fromCharCode(str.charCodeAt(i) - 12256);
                            continue
                        }
                        if (str.charCodeAt(i) > 65280 && str.charCodeAt(i) < 65375) {
                            tmp += String.fromCharCode(str.charCodeAt(i) - 65248);
                        } else {
                            tmp += String.fromCharCode(str.charCodeAt(i));
                        }
                    }

                    vnode.data.model.callback(tmp);
                });
            },
        },
    },
    mounted() {
        
    },
};
</script>

<style lang="scss" scoped>
    @import '@css/var.scss';

    .register{
        
        background: $primaryColor;
        width: 100%;
        height: 100%;
        overflow: hidden;
        ::v-deep .Zyrycode .el-form-item__error{
            position: initial;
            line-height: 18px;
        }
        .center{
            width: 800px;
            @media screen and (max-height: 768px) {
                height: 90%;
                margin: 0 auto;
                margin-top: 2%;
            }

            @media screen and (min-height: 768px) {
                // position: absolute;
                // left: 50%;
                // top: 10%;
                // transform: translate(-50%, 0%);
                margin: 100px auto;
            }

            .logo{
                text-align: center;
                font-size: 28px;
                line-height: 1em;
                letter-spacing: 2px;
                color: white;
                margin-bottom: 1em;
            }

            .form-card {
                @media screen and (max-height: 768px) {
                    overflow: auto;
                    height: calc(100% - 56px);
                }

                .form{
                    width: 500px;
                    margin: 0 auto;
                }
            }
        }
    }

    .zyrycode-label-sel{width: 150px;}
</style>