<template>
    <div class="search-bar">
        <div class="search-item">
            <div class="label">招标内容：</div>

            <div class="list">
                <div 
                class="item" 
                v-for="(item, index) in ggType"
                :class="[item.type===model.type ? 'active' : '']" 
                @click="updateModel('type', item.type)"
                :key="index"
                >
                    {{item.name}}
                </div>
            </div>
        </div>

        <div class="search-item">
            <div class="label">项目类型：</div>
            
            <div class="list">
                <div 
                class="item" 
                v-for="(item, index) in proTypes" 
                :class="[item.label===model.leixing ? 'active' : '']" 
                @click="updateModel('leixing', item.label)"
                :key="index"
                >
                    {{item.label}}
                </div>
            </div>
        </div>

        <div class="search-item">
            <div class="label">项目区域：</div>
            
            <div class="list">
                <div 
                class="item" 
                v-for="(item, index) in cityList" 
                :class="[item.cityid===model.citycode ? 'active' : '']" 
                :key="index"
                @click="updateModel('citycode', item.cityid)"
                >
                    {{item.name}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CONFIG from '@config/config';

import MODEL_MIXIN from '@mixins/model';

export default {
    mixins: [MODEL_MIXIN],
    props: {
        value: {
            type: Object,
            default: () => ({
                type: 'zbgg',
                leixing: '招标公告',
                citycode: CONFIG.head.citycode,
            }),
        },
    },
    data() {
        return {
            ggType: [
               
                { 
                    name: '招标公告',
                    type:'zbgg',
                    id:'1',
                },
                { 
                    name: '变更公告', 
                    type: 'bggg' ,
                    id:'2',
                },
                { 
                    name: '补遗答疑', 
                    type: 'bydy',
                    id:'3',
                },
                { 
                    name: '中标候选人公示', 
                    type: 'hxrgs',
                    id:'4',
                },
                { 
                    name: '中标公告', 
                    type: 'zhongbiaogg',
                    id:'5',
                },
                { 
                    name: '异常公告', 
                    type: 'ycgg',
                    id:'6',
                },
            ],
            proTypes: [
                {
                    value: '',
                    label: '全部'
                },
                {
                    value: 'jsgc',
                    label: '建设工程'
                },
                {
                    value: 'zfcg',
                    label: '政府采购'
                },
                {
                    value: 'qycg',
                    label: '企业采购'
                },
            ],
            cityList: [],
        };
    },
    methods: {
        queryCity() {
            this.$get(`${this.$store.state.api.webUrl}/index/information/getcitylist.json`, {
                parentid: CONFIG.head.citycode,
            }, data => {
                this.cityList = data;
                this.cityList.unshift({
                    name: '全部', 
                    cityid: CONFIG.head.citycode,
                });
            });
        },
        updateModel(key, value) {
            this.model[key] = value;
            this.$emit('change');
        },
    },
    mounted() {
        this.queryCity();
    }
};
</script>

<style lang="scss" scoped>
    @import '@css/var.scss';

    .search-bar{
        cursor: default;

        .search-item{
            overflow: hidden;
            line-height: 1em;
            margin-top: 10px;
            .label{
                float: left;
                color: #1b1b1b;
                margin: 6px 0;
                margin-right: -80px;
                padding: 5px;
                letter-spacing:2px
            }

            .list{
                margin-left: 100px;
                overflow: hidden;

                .item{
                    float: left;
                    color: #7a7a7a;
                    margin: 6px 0;
                    margin-right: 20px;
                    padding: 5px;
                    cursor: pointer;
                    transition: all .3s;

                    &:not(.active):hover {
                        background: $primaryColor1;
                    }

                    &.active{
                        background: #2577e3;
                        color: white;
                    }
                }
            }
        }
    }
</style>